import IconTitleDescriptionCard from 'components/card/IconTitleDescriptionCard';
import DatePicker  from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Card, FlexContainer, Grid, Icon, Loader, Table, useAPI } from 'components/lib';
import React from 'react';
import useJobs from 'hooks/useJobs';

const Dashboard = () => {
  const {
    jobsWithNoSchedules,
    jobsInProgress,
    loadingJobs,
    dateFilterParams,
    filterDate,
    selectedDayRange,
  } = useJobs();
  const {
    data,
    loading,
  } = useAPI('/api/dashboard', 'GET', dateFilterParams);
  return (
    (loading || !data) ?
    <Loader/>
    :
    <Card>
      <Grid cols={4}>
        <IconTitleDescriptionCard icon='box' title={data.estimates_status_created_quantity} description='Estimates created'/>
        <IconTitleDescriptionCard icon='send' title={data.estimates_status_sent_quantity} description='Estimates sent'/>
        <IconTitleDescriptionCard icon='check' title={data.jobs_in_progress_quantity} description='Estimates approved'/>
        <Card outline>
          <FlexContainer height='100%' justifyContent='center' gap='10px' alignItems='center'>
            <Icon image='calendar' size={20} />
            <DatePicker
              value={selectedDayRange}
              onChange={filterDate}
              colorPrimary="#0fbcf9"
              colorPrimaryLight="rgba(75, 207, 250, 0.4)"
              shouldHighlightWeekends
              inputPlaceholder="Select a date range"
            />
          </FlexContainer>
        </Card>
      </Grid>
      <Grid cols={2}>
        <Card outline title='Jobs with no scheduled projects'>
          <Table
            data={jobsWithNoSchedules}
            loading={loadingJobs}
            show={['job_code', 'customerName']}
            actions={{
              view: {url: '/jobs', col: 'id'},
            }}
          />
        </Card>
        <Card outline title='Jobs in progress'>
          <Table
            data={jobsInProgress}
            loading={loadingJobs}
            show={['job_code', 'customerName', 'approved_date']}
            actions={{
              view: {url: '/jobs', col: 'id'},
            }}
          />
        </Card>
      </Grid>
    </Card>
  )
}

export default Dashboard