import { Home } from "views/website/home";
import { Contact } from "views/website/contact";
import { Pricing } from "views/website/pricing";
import { Terms } from "views/website/terms";
import { Privacy } from "views/website/privacy";
import {
  ApproveEstimateLoading,
  GoHighLevelSync,
  InviteEmployees,
} from "views/loading/loading";

const Routes = [
  {
    path: "/",
    view: Home,
    layout: "home",
    title: "Trade Growth",
  },
  {
    path: "/contact",
    view: Contact,
    layout: "home",
    title: "Contact",
  },
  {
    path: "/pricing",
    view: Pricing,
    layout: "home",
    title: "Pricing",
  },
  {
    path: "/privacy",
    view: Privacy,
    layout: "home",
    title: "Privacy Policy",
  },
  {
    path: "/terms",
    view: Terms,
    layout: "home",
    title: "Terms & Conditions",
  },
  {
    path: "/approve-estimate",
    view: ApproveEstimateLoading,
    layout: "none",
    title: "Approve estimate",
  },
  {
    path: "/gohighlevel-sync",
    view: GoHighLevelSync,
    layout: "none",
    title: "Trade Growth sync",
  },
  {
    path: "/invite-employees",
    view: InviteEmployees,
    layout: "none",
    title: "Invite Employees",
  },
];

export default Routes;
