import React from 'react'
import Item from './item'
import EstimateEquipment from './estimateEquipment'

const OneOffItemsContainer = ({
  items,
  onDelete,
  onEdit,
  equipmentForEstimate,
  onDeleteEquipment,
  onEditEquipment
}) => {
  return (
    <div style={{
      display: 'flex',
      width: '100%',
      height: '50vh',
      border: '1px dashed lightgrey',
      borderRadius: '10px',
      padding: '20px',
      flexWrap: 'wrap',
      gap: '30px',
      overflow: 'auto',
    }}>
      {items.map((item, index) => <Item
        key={index}
        index={index}
        item={item}
        onDelete={onDelete}
        onEdit={onEdit}
      />)}
      {equipmentForEstimate.map((equipment, index) => <EstimateEquipment
        key={index}
        index={index}
        equipment={equipment}
        onDelete={onDeleteEquipment}
        onEdit={onEditEquipment}
      />)}
    </div>
  )
}

export default OneOffItemsContainer