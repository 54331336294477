/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, { useContext } from "react";
import {
  Card,
  Table,
  Animate,
  Button,
  TabView,
  AuthContext,
} from "components/lib";
import FlexContainer from "components/flexcontainer/flexContainer";
import useEstimates from "hooks/useEstimates";
import useEstimatesTable from "hooks/useEstimatesTable";

export function Estimates() {
  const {
    estimatesData,
    toggleEstimatesToShow,
    loadingEstimates,
    showActiveEstimates,
    goToCreateNewEstimate,
    handleEstimateArchiveAction,
    approveEstimate,
    sendQuote,
    handleEditEstimate,
  } = useEstimates(true);
  const { customActions } = useEstimatesTable(
    showActiveEstimates,
    handleEstimateArchiveAction,
    approveEstimate,
    sendQuote
  );

  const context = useContext(AuthContext);

  let actions = {};

  if (context.user?.permission === "admin")
    actions = { custom: customActions, edit: handleEditEstimate };

  const tab = () => (
    <Card last>
      <Table
        search
        data={estimatesData}
        badge={{
          col: "status",
          condition: [
            { value: "APPROVED", color: "green" },
            { value: "DECLINED", color: "red" },
            { value: "REVISING", color: "yellow" },
            { value: "SENT", color: "blue" },
            { value: "CREATED", color: "yellow" },
            { value: "ARCHIVED", color: "grey" },
          ],
        }}
        actions={actions}
        show={[
          "customerName",
          "email",
          "phone",
          "dateCreated",
          "estimatedValue",
          "days_to_complete",
          "status",
        ]}
        loading={loadingEstimates}
      ></Table>
    </Card>
  );
  return (
    <Animate type="pop">
      <FlexContainer justifyContent={"flex-end"} gap={10} marginY={10}>
        <Button
          text="New Estimate"
          permission="admin"
          color="blue"
          className="px-5"
          action={goToCreateNewEstimate}
        />
      </FlexContainer>

      <TabView
        name="estimates"
        onSwitch={toggleEstimatesToShow}
        labels={["Active", "Archived"]}
      >
        {tab()}
        {tab()}
      </TabView>
    </Animate>
  );
}
