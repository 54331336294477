/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment, useContext, useEffect } from 'react';
import { AuthContext, useNavigate } from 'components/lib';

export function Home(props){
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(()=>{
    if(authContext.user)
      navigate('/estimates');
    else 
      navigate('/signin');
  },[])
  return(
    <Fragment>
    </Fragment>
  );
}
