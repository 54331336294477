import { Icon } from 'components/lib';
import React, { useEffect, useState } from 'react'
import pf from './../../../media/images/empty-profile-photo.png';

const ProfilePhoto = (props) => {
  const [profilePhoto, setProfilePhoto] = useState(pf);

  useEffect(()=>{
    if(props.value && props.value[0]) {
      updatePhoto(props.value[0].data);
    } else if (props.value) {
      setProfilePhoto('https://' + props.value.url);
    }
  }, [props.value])

  function validate(file){

    //delete the previous one
    props.onChange(props.name, [{
      name: file.name,
      size: file.size,
      type: file.type
    }], true);
    
    const fileStore = [
      {
        name: file.name,
        data: file,
        url: URL.createObjectURL(file),
        size: file.size,
        type: file.type,
      }
    ];

    props.onChange && props.onChange(props.name, fileStore, true);

  }

  const updatePhoto = file => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfilePhoto(reader.result);
    }
    reader.readAsDataURL(file);
  }

  const onFileInput = e => {
    e.preventDefault();
    validate(e.target.files[0]);
    updatePhoto(e.target.files[0]);
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          marginBottom: '2rem',
          marginRight: '2rem',
          width: '7.25rem',
          height: '7.25rem',
        }}>
        <img
          style={{
            width: '7.25rem',
            height: '7.25rem',
            minWidth: '7.25rem',
            minHeight: '7.25rem',
            borderRadius: '50%',
            objectFit: 'cover'
          }}
          src={profilePhoto}
        />
        <label htmlFor='photo-upload' style={{cursor: 'pointer'}}>
          <div style={{padding: '0.4rem', borderRadius: '50%' , backgroundColor: 'white', position: 'absolute', left: '6.75rem', top: '6.75rem'}}>
              <input id='photo-upload' style={{display: 'none'}} type='file' accept="image/png, image/jpeg" onChange={onFileInput}/>
              <Icon
                image='edit'
                size={18}
              />
          </div>
        </label>
      </div>
      <div>
        <h2 style={{fontWeight: 'bold', fontSize: '1.15rem'}}>{props.companyName}</h2>
        <h3 style={{fontSize: '0.85rem'}}>Update your business photo and details</h3>
      </div>
    </div>
  )
}

export default ProfilePhoto