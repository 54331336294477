/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React from 'react';
import { Card, Animate, Button, Form, Label, SearchSelect } from 'components/lib';
import FlexContainer from 'components/flexcontainer/flexContainer';
import OneOffItemsContainer from 'components/Items/oneOffItemsContainer';
import useCreateJob from 'hooks/useCreateJob';
import useEstimates from 'hooks/useEstimates';
import { useParams } from 'react-router-dom';


export function NewEstimate(){
  const { estimateId } = useParams();
  const {
    goToEstimates,
  } = useEstimates();

  const {
    onChangeInput,
    form,
    handleAddItem,
    handleAddItemsCollection,
    handleAddEquipment,
    oneOffItems,
    equipmentForEstimate,
    submit,
    itemsOptions,
    onDeleteItem,
    addInventoryItem,
    onEditItem,
    onDeleteEquipment,
    onEditEquipment,
    loadingCustomers,
    saving,
    loadingEditScreen,
  } = useCreateJob(estimateId);

  return (
    <Animate type='pop'>

      <FlexContainer
        justifyContent={'flex-end'}
        gap={10}
        marginY={10}
      >
        <Button
          text='Go Back'
          color='blue'
          className='px-5'
          action={goToEstimates}
        />
      </FlexContainer>

      <Card loading={loadingCustomers || saving || loadingEditScreen} last>
        <FlexContainer gap='30px'>
          <FlexContainer width='40%'>
            {
              !loadingCustomers &&
              <Form
                updateOnChange
                onChange={onChangeInput}
                style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}
                data={form}
              />
            }
          </FlexContainer>
          <FlexContainer width='60%' gap='20px' flexDirection='column'>

            <FlexContainer gap='10px'>
              <FlexContainer flexDirection='column' width='100%'>
                <Label text='Add service items from price-book & inventory'/>
                <SearchSelect
                  options={itemsOptions}
                  onChange={addInventoryItem}
                  value=''
                />
              </FlexContainer>
              <FlexContainer flexDirection='column'>
                <FlexContainer minWidth='195px' gap='20px' justifyContent='space-between'>
                  <Label text='Or add service items'/>
                </FlexContainer>
                <FlexContainer gap='10px' justifyContent='space-between'>
                  <Button
                    noWrapText
                    text='Add one-off item'
                    color='blue'
                    className='px-5'
                    action={handleAddItem}
                  />
                  <Button
                    noWrapText
                    text='Add a collection of items'
                    color='blue'
                    className='px-5'
                    action={handleAddItemsCollection}
                  />
                  <Button
                    noWrapText
                    text='Add equipment'
                    color='blue'
                    className='px-5'
                    action={handleAddEquipment}
                  />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <OneOffItemsContainer
                items={oneOffItems}
                equipmentForEstimate={equipmentForEstimate}
                onDelete={onDeleteItem}
                onEdit={onEditItem}
                onDeleteEquipment={onDeleteEquipment}
                onEditEquipment={onEditEquipment}
              />
            </FlexContainer>

          </FlexContainer>
        </FlexContainer>
        <FlexContainer
          justifyContent={'flex-end'}
          gap={10}
          marginY={10}
        >
          <Button
            fullWidth
            text='Save'
            color='blue'
            className='px-5'
            action={submit}
          />
        </FlexContainer>
      </Card>

    </Animate>
  );
}
