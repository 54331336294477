import React, { createContext, useState } from 'react'

export const FilesContext = createContext({
  filesCache: null,
  setFilesCache: ()=>{},
  loadingFiles: null,
  setLoadingFiles: ()=>{},
})
const FilesProvider = ({children}) => {
  const [filesCache, setFilesCache] = useState(null);
  const [loadingFiles, setLoadingFiles] = useState();

  return (
    <FilesContext.Provider
      value={{
        filesCache,
        setFilesCache,
        loadingFiles,
        setLoadingFiles,
      }}
    >
      {children}
    </FilesContext.Provider>
  )
}

export default FilesProvider