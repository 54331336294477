import { Animate, AuthContext, Card, TabView, Table } from "components/lib";
import useJobs from "hooks/useJobs";
import React, { useContext } from "react";

const Jobs = () => {
  const {
    selectJobsToShow,
    showJobsType,
    handleJobArchiveAction,
    jobsData,
    loadingJobs,
    handleAddMedia,
    handleJobCompleteAction,
    handleBackAction,
    handleAddInformationToJob,
    loadingFiles,
  } = useJobs(true);

  const context = useContext(AuthContext);

  const customActions = [];

  if (context.user.permission === "admin" && showJobsType !== 2) {
    //2 = completed
    customActions.push({
      icon: showJobsType !== 1 ? "archive" : "corner-down-left",
      action: handleJobArchiveAction,
    });
    showJobsType !== 1 &&
      customActions.push({
        icon: "check-circle",
        action: handleJobCompleteAction,
      });
  }

  if (showJobsType === 2) {
    customActions.push({
      icon: "skip-back",
      action: handleBackAction,
    });
  }

  const loading = loadingJobs || loadingFiles;
  const tab = () => (
    <Card loading={loading}>
      <Table
        search
        data={jobsData}
        badge={{
          col: "status",
          condition: [
            { value: "APPROVED", color: "green" },
            { value: "DECLINED", color: "red" },
            { value: "REVISING", color: "yellow" },
            { value: "SENT", color: "blue" },
            { value: "CREATED", color: "yellow" },
            { value: "In progress", color: "yellow" },
            { value: "Completed", color: "green" },
          ],
        }}
        actions={{
          custom: customActions,
          edit: handleAddInformationToJob,
          view: {url: '/jobs', col: 'id'},
        }}
        show={[
          "customerName",
          "service_location",
          "revenue",
          "approved_date",
          "completion_date",
          "status",
        ]}
        loading={loading}
      ></Table>
    </Card>
  );
  return (
    <Animate type="pop">
      <TabView
        name="jobs"
        onSwitch={selectJobsToShow}
        labels={["Active", "Archived", "Completed"]}
      >
        {tab()}
        {tab()}
        {tab()}
      </TabView>
    </Animate>
  );
};

export default Jobs;
