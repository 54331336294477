import FlexContainer from "components/flexcontainer/flexContainer";
import { Animate, Button, Card, Table } from "components/lib";
import useTeams from "hooks/useTeams";
import React from "react";

const Teams = () => {
  const {
    createTeam,
    teamsTable,
    loadingTeams,
    handleDeleteTeam,
    handleEditTeam,
  } = useTeams();

  return (
    <Animate type="pop">
      <FlexContainer justifyContent={"flex-end"} gap={10} marginY={10}>
        <Button
          text={"New Team"}
          color="blue"
          permission="admin"
          className="px-5"
          action={createTeam}
        />
      </FlexContainer>

      <Card loading={loadingTeams}>
        <Table
          search
          data={teamsTable}
          actions={{
            delete: handleDeleteTeam,
            edit: handleEditTeam,
          }}
          show={["name", "color"]}
          loading={loadingTeams}
        />
      </Card>
    </Animate>
  );
};

export default Teams;
