import { TextInput } from 'components/lib';
import { moneyFormatter } from 'lib';
import { useEffect, useState } from 'react';
import { MoneyInput } from '../money/money';

export function EstimateValue(props){
  const [value, setValue] = useState(props.value);

  useEffect(()=>{
    setValue(props.value);
  },[props.value])

  return(
    <div style={{marginBottom: '35px'}} className='w-[30%]'>
      <MoneyInput
        key={ props.name }
        type={ props.type }
        form={ props.name }
        label={ props.label }
        className={ props.class }
        name={ props.name }
        value={ value }
        required={ props.required }
        valid={ props.valid }
        default={ props.default }
        text={ props.text }
        title={ props.title }
        accept={ props.accept }
        description={ props.description }
        readonly={ props.readonly }
        handleLabel={ props.handleLabel }
        placeholder={ props.placeholder }
        errorMessage={ props.errorMessage }
        onChange={ props.onChange }
        callback={props.callback}
      />
      Estimate value with discount: <span style={{color:'rgb(59 130 246)', fontWeight: 'bold'}}>{moneyFormatter(props.valueWithDiscount)}</span>
    </div>
  ); 
}

