import { MainContext } from 'contexts/MainContext';
import { getCustomers } from 'lib/services';
import { useEffect, useContext, useCallback, useState } from 'react';

const useCustomers = () => {
  const {
    customersData,
    setCustomersData,
    loadingCustomers,
    setLoadingCustomers,
  } = useContext(MainContext);
  const [customersOptions, setCustomersOptions] = useState([]);

  const updateCustomerOptions = data => {
    const options = data.map(({id, contactName}) => ({
      value: id,
      label: contactName,
    }));
    options.unshift({value: 'unselected', label: 'Please select an option'});
    setCustomersOptions(options);
  };

  useEffect(()=>{
    if(customersOptions.length) {
      setLoadingCustomers(false);
    }
  }, [customersOptions]);

  useEffect(()=>{
    if(customersData?.length) {
      updateCustomerOptions(customersData);
    }
  }, [customersData]);

  const getCustomerNameById = useCallback(customerId => {
    const customersName = customersData?.find(customer => customer.id === customerId)?.contactName;
    return customersName;
  }, [customersData]);

  const reload = useCallback(async ()=>{
    setLoadingCustomers(true);
    const data = await getCustomers();
    if(data){
      setCustomersData(data);
    }
  }, []);

  useEffect(()=>{
    if(!customersData && !loadingCustomers) {
      reload();
    }
  },[reload, customersData, loadingCustomers]);

  return {
    customersData,
    getCustomerNameById,
    customersOptions,
    loadingCustomers,
  };
}

export default useCustomers;