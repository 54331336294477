import { FilesViewer } from "components/filesViewers/filesViewers";
import { ViewContext } from "components/lib";
import { FilesContext } from "contexts/FilesContext";
import { getFile } from "lib/services";
import { useContext } from "react";

const header = [
  { name: "filename", title: "Name", sort: true },
  { name: "url", title: "url", sort: true },
  { name: "fileType", title: "Type", sort: true },
];

export const useFilesViewer = () => {
  const viewContext = useContext(ViewContext);
  const { setLoadingFiles, loadingFiles, setFilesCache, filesCache } =
    useContext(FilesContext);

  const fixURLs = (medias) => {
    return medias
      .filter((media) => !!media.url)
      .map((media) => ({ ...media, url: "https://" + media.url }));
  };
  const addTypes = (medias) => {
    return medias.map((media) => ({
      ...media,
      fileType: media.filename.split(".").at(-1),
      type: media.filename.split(".").at(-1) === "mp4" ? "video" : "image",
    }));
  };

  const downloadFile = (url) => {
    return getFile(url);
  };

  const downloadFiles = async (medias) => {
    const mediasClean = medias.filter((media) => !!media.url) || [];

    const auxMedias = mediasClean.map((media) => ({
      ...media,
      file: media.url,
    }));
    return auxMedias;
  };

  const getAllFilesFromJob = async (job) => {
    let medias = [];
    if (job.signature) {
      //don't push directly to the variable because it overrides the current
      //value of job.medias, since it's the same instance. (medias = job.medias);
      medias = medias.concat(job.signature);
      medias = medias.concat(
        job.medias.filter(
          (media) => media.filename === "signature-document.pdf"
        )
      );
    }
    if (filesCache && job && job.job_code && filesCache[job.job_code]) {
      medias = filesCache[job.job_code];
    } else {
      job.notes.forEach((note) => {
        medias = medias.concat(note.medias);
      });
      medias = fixURLs(medias);
      medias = await downloadFiles(medias);
      medias = addTypes(medias);
      setFilesCache({
        ...filesCache,
        [job.job_code]: medias,
      });
    }
    return medias;
  };

  const getFilesTable = async (job) => {
    const files = await getAllFilesFromJob(job);
    return {
      header,
      body: files,
    };
  };

  const onViewFiles = async (job) => {
    setLoadingFiles(true);
    const files = await getAllFilesFromJob(job);
    setLoadingFiles(false);
    viewContext.modal.show({
      modalContentClass: "full-width",
      customContent: <FilesViewer files={files} />,
    });
  };
  return {
    onViewFiles,
    loadingFiles,
    getFilesTable,
  };
};
