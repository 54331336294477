import { EstimateValue, ItemCostProfitPrice } from "components/lib";
import { TZDate } from "@toast-ui/calendar";
import TeamsInput from "components/form/input/teams/teams";
import TeamColor from "components/form/input/teamColor/teamcolor";
import GeneralCosts from "components/form/input/generalCosts/generalcost";

export const costPerOptions = [
  {
    value: "unit",
    label: "Unit",
  },
  {
    value: "square foot",
    label: "Square Foot",
  },
  {
    value: "inches",
    label: "Inches",
  },
];

const baseFormConst = (itemTypes, values) => ({
  itemName: {
    value: values?.itemName,
    type: "text",
    required: true,
    label: "Item Name",
    containerClass: "w-full",
  },
  itemType: {
    default: values?.itemType.value ?? values?.itemType,
    type: "select",
    options: itemTypes,
    required: true,
    label: "Item Type",
    containerClass: "w-full",
  },
  costPer: {
    default: values?.costPer.value ?? values?.costPer,
    type: "select",
    options: costPerOptions,
    required: true,
    label: "Price Per",
    containerClass: "w-full",
  },
  itemQuantity: {
    value: values?.itemQuantity,
    type: "number",
    required: true,
    label: "Quantity",
    containerClass: "w-full",
  },
  itemCostProfitPrice: {
    customComponent: ItemCostProfitPrice,
    value: {
      item_cost: values?.itemCost,
      profit_percentage: values?.profit_percentage,
      item_price: values?.itemPrice,
    },
  },
});

export const itemBaseForm = (itemTypes, values) => {
  return Object.assign(baseFormConst(itemTypes, values), {
    savePriceBook: {
      type: "checkbox",
      options: ["Yes"],
      label: "Save this item in your inventory?",
      value: false,
      containerClass: "w-full",
    },
  });
};

export const itemExtendedForm = (itemTypes, values) => {
  return Object.assign(baseFormConst(itemTypes, values), {
    itemDescription: {
      value: values?.itemDescription,
      type: "textarea",
      required: false,
      label: "Item Description",
      containerClass: "w-full",
    },
    itemQuantity: {
      value: values?.itemQuantity,
      type: "number",
      required: false,
      label: "Quantity In Stock",
      containerClass: "w-full",
    },
  });
};

export const createJobForm = (values, customersOptions) => ({
  customer: {
    default: values.customer,
    value: values.customer,
    label: "Customer",
    placeholder: "Customer Name",
    type: "select",
    options: customersOptions,
    required: true,
    errorMessage: "Please select a customer",
    containerClass: "w-full",
  },
  days: {
    value: values?.days,
    label: 'Days to complete the job',
    min: 0,
    type: 'number',
    required: true,
    errorMessage: 'Please enter days number',
    containerClass: 'w-full',
  },
  generalCosts: {
    value: values.generalCosts,
    customComponent: GeneralCosts,
  },
  estimateValue: {
    value: values.estimateValue,
    valueWithDiscount: values.valueWithDiscount,
    label: "Estimate Value",
    type: "text",
    errorMessage: "Please enter a value",
    customComponent: EstimateValue,
  },
  applyDiscount: {
    value: values?.applyDiscount,
    label: "Apply Discount",
    sufix: '%',
    type: "number",
    min: 0,
    max: 100,
    errorMessage: "Please enter a value",
    containerClass: "w-[30%]",
  },
  discount_expiration_date: {
    value: values?.discount_expiration_date,
    label: "Discount Expiration Date",
    type: "date",
    dateFormat: 'mm/dd/yyyy',
    placeholder: 'mm/dd/yyyy',
    errorMessage: "Please enter a value",
    containerClass: "w-[30%]",
  },
  extraNotes: {
    value: values?.extraNotes,
    label: "Extra Notes",
    type: "textarea",
    errorMessage: "Please enter a value",
    containerClass: "w-full",
  },
  files: {
    value: values?.files,
    type: "file",
    required: true,
    errorMessage: "Please enter a value",
    containerClass: "w-full",
  },
  files_to_keep: {
    type: "filesToKeep",
    delete: true,
    value: values?.files_to_keep,
  },
});

export const appointmentForm = (teamsOptions, jobsOptions, values, type) => {
  return {
    name: {
      value: values?.name,
      type: "text",
      required: true,
      label: "NAME OF THE PROJECT",
      containerClass: "w-full",
      disabled: type === "schedule",
    },
    team_assigned_id: {
      default: values?.team_assigned_id,
      type: "select",
      required: true,
      label: "TEAM ASSIGNED",
      containerClass: "w-[49%]",
      options: teamsOptions,
      disabled: type === "schedule",
    },
    job_id: {
      default: values?.job_id,
      type: "select",
      required: true,
      label: "JOB RELATED",
      containerClass: "w-[49%]",
      options: jobsOptions,
      disabled: type === "schedule",
    },
    start_date: {
      value: dateMMDDYYYYToYYYYMMDD(values?.start_date),
      type: "date",
      required: true,
      label: "START DATE",
      placeholder: "yyyy-mm-dd",
      containerClass: "w-[49%]",
      disabled: type === "info",
    },
    end_date: {
      value: dateMMDDYYYYToYYYYMMDD(values?.end_date),
      type: "date",
      required: true,
      label: "END DATE",
      placeholder: "yyyy-mm-dd",
      containerClass: "w-[49%]",
      disabled: type === "info",
    },
    start_time: {
      value: values?.start_time,
      type: "time",
      required: true,
      label: "START TIME",
      placeholder: "12:30 AM",
      containerClass: "w-[49%]",
      disabled: type === "info",
    },
    end_time: {
      value: values?.end_time,
      type: "time",
      required: true,
      label: "END TIME",
      placeholder: "12:30 AM",
      containerClass: "w-[49%]",
      disabled: type === "info",
    },
    notes: {
      value: values?.notes,
      type: "textarea",
      noResize: true,
      required: false,
      label: "ADD NOTES FOR THE TEAM ASSIGNED",
      containerClass: "w-full",
    },
  };
};

export const createTeamForm = (employeesFilter, getEmployeesByIds, values) => {
  return {
    name: {
      type: "text",
      label: "Team Name",
      required: true,
      value: values?.name,
    },
    color: {
      type: "color",
      label: "Team Color",
      required: true,
      value: values?.color || "#000000",
      teamName: values?.name,
      customComponent: TeamColor,
    },
    team_members: {
      customComponent: TeamsInput,
      getEmployeesByIds,
      filter: employeesFilter,
      label: "Team Members",
      required: true,
      value: values?.team_members,
    },
  };
};

export const dateTimeStringConverter = (date, time) => {
  const str = new Date(date + " " + time).toLocaleString();
  const strs = str.split(",");
  const dateNumbers = strs[0].split("/");
  const [dayRaw, monthRaw, year] = dateNumbers;
  const day = dayRaw.length === 2 ? dayRaw : "0" + dayRaw;
  const month = monthRaw.length === 2 ? monthRaw : "0" + monthRaw;
  const dateString = `${year}-${month}-${day}`;
  const finalStr = dateString + strs[1];
  return finalStr;
};

export const onlyDateFromDashStandarToSlashFormatter = (date) => {
  const dateArray = date.split('-');
  const returnDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`
  return returnDate;
};

export const onlyDateFromSlashToDashStandarFormatter = (date) => {
  const dateArray = date.split('/');
  const returnDate = `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`
  return returnDate;
};

export const TZDateConverter = (date, time) => {
  const dateTime = new Date(date + " " + time);
  return new TZDate(dateTime);
};

const dateMMDDYYYYToYYYYMMDD = (date) => {
  if (!date) return;
  const dateArray = date.split("/");
  const newDate = `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
  return newDate;
};

export const dateYYYYMMDDToMMDDYYYYTSlash = (date) => {
  if (!date) return;
  const dateArray = date.split("-");
  const newDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  return newDate;
};

export const dateConverter = (date, time) => {
  return new Date(date + " " + time);
};

export const dateAndTimeToISOStringConverter = (date, time) => {
  const dateTime = new Date(date + " " + time + " UTC");
  return dateTime.toISOString();
};

export const parseYearMonthDayToISODateString = (value) => {
  const { year, month, day } = value;
  const dateTime = new Date(year, month - 1, day);
  return dateTime.toISOString();
};

export const dateGreaterOrEqualThan = (dateString1, dateString2) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  return date1.getDate() >= date2.getDate();
};

export const dateLessThan = (dateString1, dateString2) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  date2.setDate(date2.getDate() + 1);
  return date1.getDate() < date2.getDate();
};

export const parseTabNumberToJobHistoryLogCategory = (tab) => {
  switch (tab) {
    case 0:
      return "all";
    case 1:
      return "notes";
    case 2:
      return "files";
    case 3:
      return "others";
  }
};

export const ISOStringToLocaleString = (stringDateTime) => {
  const dateTime = new Date(stringDateTime);
  const stringParsed = dateTime.toLocaleString();
  return stringParsed;
};

export const calculateTimeFromTimeToTime = (from, to) => {
  const diffInMiliSeconds = Math.abs(new Date(from) - new Date(to));
  const diffInMinutes = Math.floor((diffInMiliSeconds/1000)/60);
  const hours = Math.floor(diffInMinutes/60);
  const minutes = diffInMinutes % 60;
  return `${hours}h ${minutes}min`;
}