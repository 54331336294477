import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useState } from 'react';
import { Label } from 'components/lib';
import Style from '../input.tailwind';

const TimeInput = props => {
    const [value, setValue] = useState(props.value);
    const onChange = value => {
        setValue(value);
        props.onChange?.(props.name, value, true);
    }
    return (
        <div
            className={ Style.input + " " + props.containerClass }
        >
            { props.label && 
            <Label
                text={ props.label }
                required={ props.required }
                for={ props.name }
            /> }
            <TimePicker
                disableClock={true}
                onChange={onChange}
                value={value}
                format='h:mm aaaa'
            />
        </div>
    )
}

export default TimeInput;
