import axios from "axios";
import { convertItemToAPI, convertJobToAPI } from "./lib";

export const getAllJobs = async (body) => {
  return await axios({
    method: "get",
    data: body,
    url: "/api/jobs",
  }).then((response) => response.data);
};

export const getAllEstimates = async (body) => {
  return await axios({
    method: "get",
    data: body,
    url: "/api/estimates",
  }).then((response) => response.data);
};

export const createJob = async (job) => {
  const body = convertJobToAPI(job);
  return await axios({
    method: "post",
    data: body,
    url: "/api/create-jobs",
  }).then((response) => response.data);
};

export const updateJob = async (job, estimateId) => {
  const body = convertJobToAPI(job);
  return await axios({
    method: "PUT",
    data: body,
    url: "/api/update-jobs/" + estimateId,
  }).then((response) => response.data);
};

export const completeJob = async (jobId) => {
  return await axios({
    method: "PATCH",
    url: "/api/complete-jobs/" + jobId,
  }).then((response) => response.data);
};

export const backJob = async (jobId) => {
  return await axios({
    method: "PATCH",
    url: "/api/back-jobs/" + jobId,
  }).then((response) => response.data);
};

export const turnEstimateToJob = async (id) => {
  return await axios({
    method: "post",
    url: "/api/turn-estimate-job/" + id,
  }).then((response) => response.data);
};

export const sendEstimateOffer = async (id) => {
  const appDomain = window.location.origin;

  return await axios({
    method: "get",
    url: "/api/send/estimate-offer/" + id,
    params: {
      url: `${appDomain}/approve-estimate`,
    },
  }).then((response) => response.data);
};

export const archiveJob = async (id) => {
  return await axios({
    method: "patch",
    url: "/api/archive-jobs/" + id,
  }).then((response) => response.data);
};

export const unarchiveJob = async (id) => {
  return await axios({
    method: "patch",
    url: "/api/unarchive-jobs/" + id,
  }).then((response) => response.data);
};

export const getItems = async () => {
  return await axios({
    method: "get",
    url: "/api/items",
  }).then((response) => response.data.data);
};

export const getEquipments = async () => {
  return await axios({
    method: "get",
    url: "/api/equipment",
  }).then((response) => response.data.data);
};

export const getItemsCollections = async () => {
  return await axios({
    method: "get",
    url: "/api/collections",
  }).then((response) => response.data.data);
};

export const getItemTypes = async () => {
  return await axios({
    method: "get",
    url: "/api/items-type",
  }).then((response) => response.data.data);
};

export const postItem = async (item) => {
  const data = convertItemToAPI(item);
  return await axios({
    method: "post",
    data,
    url: "/api/create-item",
  }).then((response) => response.data).catch(err => {
    throw err;
  });
};

export const deleteItem = async (id) => {
  return await axios({
    method: "delete",
    url: "/api/remove-item/" + id,
  }).then((response) => response.data.data);
};

export const putItem = async (id, item) => {
  const data = convertItemToAPI(item);
  return await axios({
    method: "put",
    url: "/api/update-item/" + id,
    data,
  }).then((response) => response.data.data);
};

export const patchQuantity = async (itemId, quantity) => {
  const data = { qtd: quantity };
  return await axios({
    method: "patch",
    url: "/api/item-quantity/" + itemId,
    data,
  }).then((response) => response.data.data);
};

export const getAllAppointments = async () => {
  return await axios({
    method: "get",
    url: "/api/appointments",
  }).then((response) => response.data);
};

export const saveNewAppointment = async (data) => {
  return await axios({
    method: "POST",
    url: "/api/appointments",
    data,
  }).then((response) => response.data).catch(err => {
    throw err;
  });
};

export const getAllTeams = async (body) => {
  return await axios({
    method: "get",
    data: body,
    url: "/api/employee-team",
  }).then((response) => response.data);
};

export const postTeam = async (body) => {
  return await axios({
    method: "post",
    data: body,
    url: "/api/employee-team",
  }).then((response) => response.data);
};

export const deleteTeam = async (id) => {
  return await axios({
    method: "delete",
    url: "/api/remove-employee-team/" + id,
  }).then((response) => response.data);
};

export const putTeam = async (body, id) => {
  return await axios({
    method: "put",
    data: body,
    url: "/api/update-employee-team/" + id,
  }).then((response) => response.data);
};

export const putAppointment = async (id, data) => {
  return await axios({
    method: "put",
    url: "/api/appointments/" + id,
    data,
  }).then((response) => response.data.data);
};

export const deleteAppointment = async (id) => {
  return await axios({
    method: "delete",
    url: "/api/appointments/" + id,
  }).then((response) => response.data.data);
};

export const getCustomers = async () => {
  return await axios({
    method: "get",
    url: "/api/customers",
  }).then((response) => response.data.data)
  .catch(e=>{
    console.log('cannot get customers', e);
  });
};

export const approveEstimate = async (token, signature) => {
  const formData = new FormData();
  formData.append("signature", signature);
  return await axios({
    method: "post",
    url: `/api/approve-estimate/${token}`,
    data: formData,
  }).then((response) => response.data.data);
};

export const validateToken = async (token) => {
  return await axios({
    method: "get",
    url: `/api/validate-token`,
    params: {
      token
    }
  }).then((response) => response.data.data);
};

export const syncGoHighLevel = async (code, account) => {
  return await axios({
    method: "get",
    url: `/api/oauth/callback/gohighlevel`,
    params: {
      code,
      account,
    },
  }).then((response) => response.data.data);
};

export const inviteEmployees = async (code, account) => {
  return await axios({
    method: "get",
    url: `/api/send-invites`,
  }).then((response) => response.data.data);
};

export const getEmployees = async () => {
  return await axios({
    method: "get",
    url: "/api/employees",
  }).then((response) => response.data.data);
};

export const getFile = async (url) => {
  return await axios({
    method: "get",
    url,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    transformRequest: [
      (data, headers) => {
        delete headers["Authorization"];
        return JSON.stringify(data);
      },
    ],
    responseType: "blob",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("ERROR:", error);
    });
};

export const getTimesheet = async () => {
  return await axios({
    method: "get",
    url: "/api/employees/timesheet",
  }).then((response) => response.data.data);
}

export const clockInOrOut = async (job_id, action) => {
  const data = {
    action,
    job_id
  }
  return await axios({
    method: "post",
    data,
    url: "/api/employees/clock-in-clock-out",
  }).then((response) => response.data.data);
}

export const getClockInOrOutAvailability = async (job_id) => {
  return await axios({
    method: "get",
    url: `/api/employees/get-action-available/job/${job_id}`,
  }).then((response) => response.data);
}

export const getProfitability = async () => {
  return await axios({
    method: "get",
    url: `/api/profitability/jobs`,
  }).then((response) => response.data.data);
}
