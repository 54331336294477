import Appointments from "views/dashboard/appointments";
import Dashboard from "views/dashboard/dashboard";
import { Estimates } from "views/dashboard/estimates";
import { Help } from "views/dashboard/help";
import Jobs from "views/dashboard/jobs";
import { NewEstimate } from "views/dashboard/newEstimate";
import PriceBookInventory from "views/dashboard/priceBookInventory";
import Teams from "views/dashboard/teams";
import ViewJob from "views/dashboard/viewJob";
import { OnboardingView } from "views/onboarding/onboarding";
import Timesheet from "views/dashboard/timesheet";
import Profitability from "views/dashboard/profitability";

const Routes = [
  {
    path: "/estimates",
    view: Estimates,
    layout: "app",
    permission: "user",
    title: "Estimates",
  },
  {
    path: "/estimates/new",
    view: NewEstimate,
    layout: "app",
    permission: "admin",
    title: "Create estimate",
  },
  {
    path: "/estimates/edit/:estimateId",
    view: NewEstimate,
    layout: "app",
    permission: "admin",
    title: "Edit estimate",
  },
  {
    path: "/jobs",
    view: Jobs,
    layout: "app",
    permission: "user",
    title: "Jobs",
  },
  {
    path: "/jobs/:jobId",
    view: ViewJob,
    layout: "app",
    permission: "user",
    title: "Jobs",
  },
  {
    path: "/schedules",
    view: Appointments,
    layout: "app",
    permission: "user",
    title: "Projects",
  },
  {
    path: "/timesheet",
    view: Timesheet,
    layout: "app",
    permission: "user",
    title: "Timesheet",
  },
  {
    path: "/pricebook",
    view: PriceBookInventory,
    layout: "app",
    permission: "admin",
    title: "Price-book & Inventory",
  },
  {
    path: "/dashboard",
    view: Dashboard,
    layout: "app",
    permission: "admin",
    title: "Dashboard",
  },
  {
    path: "/profitability",
    view: Profitability,
    layout: "app",
    permission: "admin",
    title: "Profitability",
  },
  {
    path: "/teams",
    view: Teams,
    layout: "app",
    permission: "admin",
    title: "Teams",
  },
  {
    path: "/welcome",
    view: OnboardingView,
    layout: "onboarding",
    permission: "user",
    title: "Welcome",
  },
  {
    path: "/help",
    view: Help,
    layout: "app",
    permission: "user",
    title: "Get Help",
  },
];

export default Routes;
