/***
 *
 *   APP NAV
 *   Primary navigation used inside the main app component
 *
 *   PROPS
 *   type: fixed or popup (style of nav on mobile)
 *   items: array of objects containing label, link and icon (optional)
 *
 **********/

import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext, Button, Icon, useNavigate } from "components/lib";
import "./app.scss";

export function AppNav(props) {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  // state
  const [open, setOpen] = useState(false); // mobile is open
  const [expanded, setExpanded] = useState(false); // desktop is expanded

  return (
    <nav
      className={`sidebar popup ${open && "open"} ${expanded && "expanded"}`}
      onMouseEnter={(e) => setExpanded(true)}
      onMouseLeave={(e) => setExpanded(false)}
    >
      <Button
        icon={open ? "x" : "menu"}
        color={"dark"}
        size={12}
        className="btn-togglenav"
        action={(e) => setOpen(!open)}
      />

      <Button navIcon action={(e) => navigate("/")} customIcon="app-icon" />
      <section className="nav-links">
        {props.items?.map((item) => {
          if (
            item.permission && context.permission
              ? context.permission[item.permission]
              : true
          ) {
            if (item.link) {
              return (
                <NavLink
                  key={item.label}
                  to={item.link}
                >
                  {item.icon && (
                    <Icon
                      className="icon"
                      image={item.icon}
                      size={open ? 15 : 18}
                      color={open ? "dark" : "light"}
                    />
                  )}
                  {item.label && <span className="label">{item.label}</span>}
                </NavLink>
              );
            }

            return (
              <div key={item.label} onClick={item.action}>
                {item.icon && (
                  <Icon
                    className="icon"
                    image={item.icon}
                    size={open ? 15 : 18}
                    color={open ? "dark" : "light"}
                  />
                )}
                {item.label && <span className="label">{item.label}</span>}
              </div>
            );
          }
          return <></>;
        })}
        <div
          className='bottom-nav'
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            position: 'absolute',
            top: 0,
            left: '5px',
            zIndex: -1,
          }}
        >
          {
            props.bottomItems.map(item =>{
              if (item.link) {
                return(
                  <NavLink
                    key={item.label}
                    to={item.link}
                  >
                    {item.icon && (
                      <Icon
                        className="icon"
                        image={item.icon}
                        size={open ? 15 : 18}
                        color={open ? "dark" : "light"}
                      />
                    )}
                    {item.label && <span className="label">{item.label}</span>}
                  </NavLink>
                )
              }
              return (
                <div 
                  style={{ width: '90%' }}
                  key={item.label}
                  onClick={item.action}
                >
                  {item.icon && (
                    <Icon
                      className="icon"
                      image={item.icon}
                      size={open ? 15 : 18}
                      color={open ? "dark" : "light"}
                    />
                  )}
                  {item.label && <span className="label">{item.label}</span>}
                </div>
              );
            })
          }
        </div>
      </section>
    </nav>
  );
}
