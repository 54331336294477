import JobNotes from "components/jobs/jobNotes";
import MainInfo from "components/jobs/mainInfo";
import { Header, Loader } from "components/lib";
import useViewJob from "hooks/useViewJob";
import React from "react";
import { useParams } from "react-router-dom";
import Appointments from "components/jobs/appointments";
import Items from "components/jobs/items";
import Files from "components/jobs/files";
import HistoryLog from "components/jobs/historyLog";

const ViewJob = () => {
  const { jobId } = useParams();
  const {
    jobInfo,
    addAppointment,
    addNote,
    filesTable,
    loadingJobs,
    jobLogsTable,
    switchHistoryLogTab,
  } = useViewJob(jobId);
  return jobInfo && filesTable && !loadingJobs ? (
    <>
      <Header title={`Job #${jobInfo?.job_code}`} />
      <MainInfo
        jobId={jobInfo.id}
        serviceName={jobInfo?.service?.name}
        serviceAddress={jobInfo?.service?.address}
        servicePhone={jobInfo?.service?.phone}
        serviceEmail={jobInfo?.service?.email}
        billToName={jobInfo?.bill_to?.name}
        billToAddress={jobInfo?.bill_to?.address}
        billToPhone={jobInfo?.bill_to?.phone}
        billToEmail={jobInfo?.bill_to?.email}
      />
      <JobNotes notes={jobInfo?.notes} addNote={addNote} />
      <Appointments
        appointments={jobInfo?.appointments}
        addAppointment={addAppointment}
      />
      <Items items={jobInfo?.items} equipment={jobInfo.equipment_used} />
      <Files files={filesTable} />
      <HistoryLog
        historyLogTable={jobLogsTable}
        switchTab={switchHistoryLogTab}
      />
    </>
  ) : (
    <Loader />
  );
};

export default ViewJob;
