import React from 'react'
import ItemBodyItem from './itemBodyItem'

const ItemBody = ({values}) => {
  return (
    <div style={{display: 'flex', rowGap: '15px', flexWrap: 'wrap', justifyContent: 'space-between'}}>
      <ItemBodyItem title='Item type:' width='45%' content={values.itemType.label}/>
      <ItemBodyItem title='Item quantity:' width='45%' content={values.itemQuantity}/>
      <ItemBodyItem title='Item cost:' width='45%' content={values.itemCost}/>
      <ItemBodyItem title='Price per:' width='45%' content={values.costPer.label}/>
      <ItemBodyItem title='Item price:' width='45%' content={"$" + values.itemPrice}/>
      <ItemBodyItem title='Item total price:' width='45%' content={"$" + values.totalPrice}/>
    </div>
  )
}

export default ItemBody