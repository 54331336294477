import React from 'react';
import Header from './itemHeader';
import EquipmentBody from './equipmentBody';

const EstimateEquipment = ({equipment, index, onDelete, onEdit}) => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '280px',
        border: '1px solid lightgrey',
        borderRadius: '10px',
        padding:'20px',
        gap:'10px',
        height:'fit-content',
      }}>
        <Header itemName={equipment.name} isEquipment index={index} onEdit={onEdit} onDelete={onDelete}/>
        <hr/>
        <EquipmentBody values={equipment}/>
      </div>
    )
  }
  
  export default EstimateEquipment