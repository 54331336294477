import { Button, Card, FlexContainer, Loader, Select } from 'components/lib'
import React from 'react'
import AlreadyClocked from './alreadyClocked'

const ClockInOrOutComponent = ({
  changeSelectedJob,
  clockInOrOut,
  disabledButtons,
  jobOptions,
  loadingAvailability,
  clockedInTime,
  selectedJob,
}) => {
  return (
    <Card title='Clock In & Out' outline>
      <FlexContainer width='350px'>
        Please remember to clock in and out of a job when you start and finish working, respectively.
        <br/> <br/>
        To do that, simply choose the job in which you will start to work on using the dropdown below and hit the "Clock In" button. Once you're finished, hit "Clock Out" - no need to select the job again.
      </FlexContainer>
      <Select
        options={jobOptions}
        name='job'
        containerClass='mt-4 mb-8'
        label='Job'
        onChange={changeSelectedJob}
        default={selectedJob}
      />
      { loadingAvailability ? <Loader className='!top-3/4'/> :
        <>
          {
            (disabledButtons.clockin && clockedInTime) ?
            <AlreadyClocked
              clockType='Clock In'
              clockedTime={clockedInTime}
            />
            : <Button
              params={['clock_in', 'clock in']}
              color={ 'blue' }
              text={ 'Clock In' }
              action={ clockInOrOut }
              className={ 'rounded-lg mb-4' }
              fullWidth={ true }
              disabled={disabledButtons.clockin}
            />
          }
          <Button
            params={['clock_out', 'clock out']}
            color={ 'blue' }
            text={ 'Clock Out' }
            action={ clockInOrOut }
            className={ 'rounded-lg' }
            fullWidth={ true }
            disabled={disabledButtons.clockout}
          />
        </>
      }
    </Card>
  )
}

export default ClockInOrOutComponent