import ItemsCollectionsView from "components/itemsCollectionsView/itemsCollectionsView";
import ItemsView from "components/itemsView/itemsView";
import { Animate, TabView } from "components/lib";
import useItems from "hooks/useItems";
import React, { useState } from "react";

const PriceBookInventory = () => {
  const {
    handleAddQuantity,
    handleDeleteItem,
    handleCreateItem,
    handleEditItem,
    handleCreateCollection,
    handleCreateEquipment,
    handleEditEquipment,
    handleDeleteEquipment,
    handleEditCollection,
    handleDeleteCollection,
    loadingItems,
    itemsTable,
    equipmentsTable,
    collectionsTables,
  } = useItems();
  const [tab, setTab] = useState(0);
  return (
    <Animate type="pop">
      <TabView
        name="estimates"
        labels={["Items", "Collections"]}
        onSwitch={tab => {setTab(tab)}}
      />
      {
        tab === 0 ?
        <ItemsView
          handleAddQuantity={handleAddQuantity}
          handleDeleteItem={handleDeleteItem}
          handleCreateItem={handleCreateItem}
          handleEditItem={handleEditItem}
          handleCreateEquipment={handleCreateEquipment}
          handleEditEquipment={handleEditEquipment}
          handleDeleteEquipment={handleDeleteEquipment}
          equipmentsTable={equipmentsTable}
          loadingItems={loadingItems}
          itemsTable={itemsTable}
        /> :
        <ItemsCollectionsView
          handleCreateCollection={handleCreateCollection}
          loadingItems={loadingItems}
          collectionsTables={collectionsTables}
          handleEditCollection={handleEditCollection}
          handleDeleteCollection={handleDeleteCollection}
        />
      }
    </Animate>
  );
};

export default PriceBookInventory;
