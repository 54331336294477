export const minBetween = (...numbers) => {
  let min;
  min = numbers[0];
  numbers.forEach(number => {
    if(number < min)
      min = number;
  });
  return min;
}
export const maxBetween = (...numbers) => {
  let max;
  max = numbers[0];
  numbers.forEach(number => {
    if(number > max)
      max = number;
  });
  return max;
}

export const moneyFormatter = amount => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  const formated = formatter.format(amount);
  return formated;
}

export const getContrastColor = backgroundColor => {
  var letterColor = 'black';
  try {
    var c = backgroundColor.substring(1);      // strip #
    var rgb = parseInt(c, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >>  8) & 0xff;  // extract green
    var b = (rgb >>  0) & 0xff;  // extract blue
  
    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  
    if (luma < 100) {
      letterColor = 'white';
    }
  } catch (e) {
    console.log('invalid color format');
  }

  return letterColor;
}

export const checkValueDifferentFromNullAndUndefinedAndNaN = value => {
  return (value !== null && value !== undefined && value !== NaN);
}

export const getPriceByCostAndProfitPercentage = (cost, profitPercent) => {
  const cleanedProfitValue = profitPercent || 0;
  const profitPerOne = parseFloat(cleanedProfitValue) / 100;
  const price = parseFloat(cost) + (cost * profitPerOne);
  return price.toFixed(2);
}