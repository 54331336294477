import { Button, Card, FlexContainer, Header } from 'components/lib'
import React from 'react'
import JobNote from './jobNote'

const JobNotes = ({notes, addNote}) => {
  return (
    <Card>
      <Header>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <h1>Job Notes</h1>
          <Button
            text='Add Note'
            color='blue'
            action={addNote}
          />
        </div>
      </Header>
        <FlexContainer flexDirection='column' rowGap='1em'>
            { notes?.length?
                notes.map((note, index) => <JobNote key={index} note={note}/>)
              : <>No notes to show</>
            }
        </FlexContainer>
    </Card>
  )
}

export default JobNotes