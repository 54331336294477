import { AuthContext } from "components/lib";
import { useContext, useEffect, useState } from "react";

const useEstimatesTable = (
  showActiveEstimates,
  handleEstimateArchiveAction,
  approveEstimate,
  sendQuote
) => {
  const [customActions, setCustomActions] = useState([]);
  useEffect(() => {
    const actions = [
      // { customIcon: showActiveEstimates ? 'archive-icon' : 'unarchive-icon', action: handleEstimateArchiveAction},
      {
        icon: showActiveEstimates ? "archive" : "corner-down-left",
        action: handleEstimateArchiveAction,
      },
    ];

    if (showActiveEstimates) {
      actions.push(
        {
          icon: "check-square",
          action: approveEstimate,
          condition: { col: "status", value: "SENT" },
        },
        {
          icon: "send",
          action: sendQuote,
          condition: { col: "status", value: "SENT" },
        },
        {
          icon: "check-square",
          action: approveEstimate,
          condition: { col: "status", value: "APPROVED" },
        },
        {
          icon: "send",
          action: sendQuote,
          condition: { col: "status", value: "CREATED" },
        }
      );
    }

    setCustomActions(actions);
  }, [showActiveEstimates]);
  return {
    customActions,
  };
};

export default useEstimatesTable;
