import React, { useEffect, useState } from "react";
import { Label, Error, ClassHelper } from "components/lib";
import Style from "./../input.tailwind.js";
import { RichTextarea } from "rich-textarea";

const INPUT_HEIGHT = 100;
const INPUT_WIDTH = 400;

const convertSeparatedName = (nameToConvert, separationFrom, separationTo) => {
  if(!nameToConvert || !separationFrom || !separationTo) return '';
  const arrayName = nameToConvert.trim().split(separationFrom);
  let dashName = '';
  if(arrayName[0]) {
    dashName = arrayName[0];
    arrayName.forEach((word, index) => {
      if(index > 0) {
        dashName += (separationTo + word);
      }
    })
  }
  return dashName;
}

const convertFromDashToSpace = (dashName) => convertSeparatedName(dashName, '_', ' ');
const convertFromSpaceToDash = (dashName) => convertSeparatedName(dashName, ' ', '_');

const SPACER = "~~";
const style = {
  height: INPUT_HEIGHT,
  lineHeight: "36px",
  width: INPUT_WIDTH,
  whiteSpace: "pre-wrap",
  overflowWrap: "normal",
};

const Menu = ({ items, onSelect }) => {
  return (
    <div
      style={{
        position: "relative",
        left: 0,
        width: INPUT_WIDTH,
        fontSize: "12px",
        borderRadius: "3px",
        zIndex: "2",
      }}
    >
      <ul
        style={{
          listStyleType: "none",
          display: "flex",
          gap: "3px",
          margin: 0,
          padding: 0,
          cursor: "pointer",
          background: "white",
        }}
      >
        {items.map((item) => (
          <li
            key={item.id}
            onMouseDown={() => onSelect(item)}
            style={{
              width: "fit-content",
              background: "#D9D9D9",
              padding: "2px 10px",
              color: "#4A5567",
              borderRadius: "15px",
            }}
          >
            {`${item.fullName}`}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Tag = ({ children, selected }) => {
  return (
    <span
      style={{
        background: selected ? "gray" : "#D9D9D9",
        padding: "2px 14px",
        margin: "3px",
        color: "#4A5567",
        borderRadius: "15px",
        height: "30px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </span>
  );
};

const TeamsInput = (props) => {
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [selectedTeamMembersNames, setSelectedTeamMembersNames] = useState([]);
  const [text, setText] = useState("");
  const [pos, setPos] = useState(null);
  const [error, setError] = useState(
    props.errorMessage || "Please enter a value"
  );
  const [teamMembersFiltered, setTeamMembersFiltered] = useState([]);

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setTeamMembersFiltered(props.filter(text, selectedTeamMembers));
  }, [text, selectedTeamMembers]);

  useEffect(() => {
    if (props.value?.length && props.getEmployeesByIds) {
      const employees = props
        .getEmployeesByIds(props.value)
        .filter((employee) => employee);
      setSelectedTeamMembers(employees);
      setSelectedTeamMembersNames(employees.map((employee) => convertFromSpaceToDash(employee.name)));
    }
  }, []);

  useEffect(() => {
    props.onChange?.(props.name, selectedTeamMembers, true);
  }, [selectedTeamMembers]);

  const tagsText =
    selectedTeamMembersNames.length > 0
      ? SPACER +
        selectedTeamMembersNames.join(SPACER + " " + SPACER) +
        (selectedTeamMembersNames.length ? SPACER : "")
      : "";
  const textStart = tagsText.length;
  const tagIndex =
    pos == null
      ? -1
      : selectedTeamMembersNames.reduce(
          (acc, t, i) => {
            if (acc[0] !== -1) return acc;
            const prev = acc[1];
            acc[1] += t.length + SPACER.length * 2 + 1;
            if (prev <= pos && pos < acc[1]) {
              acc[0] = i;
            }
            return acc;
          },
          [-1, 0]
        )[0];

  function validate(e) {
    setFocused(false);

    let value = e ? e.target.value : "";
    let valid = undefined;
    let message = "Please check all team members are added correctly";

    const members = value.trim().split("~~ ~~");
    if (members.length === 1 && members[0] !== "") {
      const m = members[0];
      const slashes = [m[0], m[1], m.at(-1), m.at(-2)];
      valid = slashes.reduce((prev, next) => prev && next === "~", true);
    } else if (members.length > 1) {
      const m0 = members[0];
      const mf = members.at(-1);
      const slashes = [m0[0], m0[1], mf.at(-1), mf.at(-2)];
      valid = slashes.reduce((prev, next) => prev && next === "~", true);
    }
    if (!members[0]) {
      valid = false;
      message = "Please enter a value";
    }
    if (!valid) {
      setError(message);
    }

    // update the parent form
    props.onChange?.(props.name, selectedTeamMembers, valid);
  }

  // style
  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
    noResize: props.noResize,
  });

  return (
    <div
      style={{ position: "relative" }}
      className={Style.input + " " + props.containerClass}
    >
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}
      <RichTextarea
        wrap="hard"
        cols={50}
        id={props.id}
        name={props.name}
        disabled={props.disabled}
        className={textStyle}
        placeholder={props.placeholder}
        onChange={(e) => {
          setText(e.target.value.slice(textStart));
        }}
        onBlur={(e) => validate(e)}
        style={style}
        value={tagsText + text}
        onFocus={() => setFocused(true)}
        onKeyDown={(e) => {
          if (!pos) return;
          switch (e.code) {
            case "Enter":
              e.preventDefault();
              if (!pos) return;
              if (!text || selectedTeamMembersNames.includes(text)) return;
              if (!teamMembersFiltered[0]?.fullName) return;
              setSelectedTeamMembersNames((prev) => [
                ...prev,
                convertFromSpaceToDash(teamMembersFiltered[0].fullName.trim()),
              ]);
              setSelectedTeamMembers((prev) => [
                ...prev,
                teamMembersFiltered[0],
              ]);
              setText("");
              break;
            case "Backspace":
              if (textStart < pos) return;
              e.preventDefault();
              setSelectedTeamMembersNames((prev) => {
                const next = [...prev];
                const splice = tagIndex === -1 ? prev.length - 1 : tagIndex;
                next.splice(splice, 1);
                return next;
              });
              setSelectedTeamMembers((prev) => {
                const next = [...prev];
                const splice =
                  tagIndex === -1 ? prev.fullName.length - 1 : tagIndex;
                next.splice(splice, 1);
                return next;
              });
              break;
            case "Delete":
              if (textStart < pos) return;
              e.preventDefault();
              break;
            default:
              break;
          }
        }}
        onSelectionChange={(r) => {
          if (r.focused) {
            setPos(r.selectionStart);
          } else {
            setPos(null);
          }
        }}
      >
        {() => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {[
                  ...selectedTeamMembersNames.flatMap((t, i) => {
                    const nameToDisplay = convertFromDashToSpace(t);
                    return [
                    <Tag
                      key={i}
                      selected={i.toString() === tagIndex.toString()}
                    >
                      {nameToDisplay}
                    </Tag>,
                  ]}),
                  text
                    ? text
                    : pos === textStart && (
                        <span
                          key={"__placeholder"}
                          style={{ color: "darkgray" }}
                        >
                          Type anything and press Enter key...
                        </span>
                      ),
                ]}
              </div>
            </>
          );
        }}
      </RichTextarea>
      {focused && text && (
        <Menu
          items={teamMembersFiltered}
          onSelect={(teamMember) => {
            selectedTeamMembersNames.push(convertFromSpaceToDash(teamMember.fullName.trim()));
            setSelectedTeamMembersNames([...selectedTeamMembersNames]);
            setSelectedTeamMembers((prev) => [...prev, teamMember]);
            setText("");
          }}
        />
      )}
      {props.valid === false && <Error message={error} />}
    </div>
  );
};

export default TeamsInput;
