import { Card, Table } from 'components/lib';
import React from 'react'

const Files = ({files}) => {
  const reveal = (file) => {
    window.open(file.url, '_blank');
  }
  return (
    <Card title='Documents'>
      <Table
        data={files}
        show={['filename', 'fileType']}
        actions={{
          custom: [
            { icon: 'eye', action: reveal },
          ],
        }}
      />
    </Card>
  )
}

export default Files