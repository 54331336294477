/***
*
*   BADGE
*   Text badge with background color
*
*   PROPS
*   text: string to be displayed
*   color: blue/red/green/orange (default: purple)
*
**********/

import { ClassHelper } from 'components/lib';
import Style from './badge.tailwind.js';
import TextStyle from './badgetext.tailwind.js';

export function Badge(props){

  const badgeStyle = ClassHelper(Style, {

    [props.color]: true,
    className: props.className,
    fitContent: props.fitContent,
    textWhite: !props.textColor,
    [props.textColor]: true,

  })

  const textStyle = ClassHelper(TextStyle, {
    clickable: props.onClick,
  })

  return (

    <span style={props.style} className={ badgeStyle }>
      <div className={ textStyle } onClick={props.onClick}>{ props.text }</div>
      { props.delete &&
        <div onClick={props.delete} className='badge-close'>x</div>
      }
    </span>

  );
}