import { Error, Label } from 'components/lib';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Style from './../input.tailwind.js';

export const MultiSelect = ({options, onChange, callback, required, name, label, containerClass, valid, errorMessage, value}) => {
  
  const getOptionObjectFromId = id => options.find(option => option.value === id);
  const getOptionObjectsFromIds = ids => ids?.map(id => getOptionObjectFromId(id));

  const [internalValue, setInternalValue] = useState(getOptionObjectsFromIds(value));
  
  function change(value){
    const valueIsAnEmptyArray = Array.isArray(value) && !value.length;
    const valid = required && valueIsAnEmptyArray ? false : true;
    const valueToSend = value.map(item=>item.value);
    onChange(name, valueToSend, valid);
    setInternalValue(value);
    callback && callback(valueToSend);
  }

  return (
    <div className={ Style.input  + " " + containerClass }>

      { label && 
        <Label
          text={ label }
          required={ required }
          for={ name }
        /> }
      <Select
        closeMenuOnSelect={false}
        value={internalValue}
        isMulti
        options={options}
        onChange={change}
      />
        
      { valid === false &&
        <Error message={ errorMessage }/> }
    </div>
  )
}