import React from 'react';
import FlexContainer from "components/flexcontainer/flexContainer";
import { Button, Card, Table } from "components/lib";
import ItemsTable from './itemsTable';

const ItemsView = ({
  handleAddQuantity,
  handleDeleteItem,
  handleCreateItem,
  handleEditItem,
  loadingItems,
  itemsTable,
  handleCreateEquipment,
  handleEditEquipment,
  handleDeleteEquipment,
  equipmentsTable,
}) => {
  return (
    <Card loading={loadingItems}>
      <FlexContainer justifyContent={"flex-end"} gap={10} marginY={10}>
        <Button
          text={"Add Item"}
          color="blue"
          className="px-5"
          action={handleCreateItem}
        />
        <Button
          text={"Add Equipment"}
          color="blue"
          className="px-5"
          action={handleCreateEquipment}
        />
      </FlexContainer>
      <Card title='Items'>
        <ItemsTable
          handleAddQuantity={handleAddQuantity}
          handleDeleteItem={handleDeleteItem}
          handleEditItem={handleEditItem}
          itemsTable={itemsTable}
          loadingItems={loadingItems}
        />
      </Card>
      <Card title='Equipment'>
        <Table
          search
          data={equipmentsTable}
          actions={{
            delete: handleDeleteEquipment,
            edit: handleEditEquipment,
          }}
          show={[
            'name',
            'hourly_rate',
            'daily_rate',
            'description',
          ]}
          loading={loadingItems}
        />
      </Card>
    </Card>
  )
}

export default ItemsView