import { AuthContext, Button, ViewContext } from "components/lib";
import { MainContext } from "contexts/MainContext";
import {
  deleteAppointment,
  getAllAppointments,
  putAppointment,
  saveNewAppointment,
} from "lib/services";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  dateConverter,
  appointmentForm,
  dateAndTimeToISOStringConverter,
} from "./lib";
import useJobs from "./useJobs";
import useTeams from "./useTeams";
import ItemBodyItem from "components/Items/itemBodyItem";

const AppointmentInfoModalContent = ({ event, editEvent }) => {
  const context = useContext(AuthContext);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>
          <b>{event.name}</b>
        </h1>
        {context.user.permission === "admin" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              minWidth: "80px",
            }}
          >
            <Button icon="trash" action={() => editEvent("delete", event)} />
            <Button icon="clock" action={() => editEvent("schedule", event)} />
            <Button icon="edit" action={() => editEvent("info", event)} />
          </div>
        )}
      </div>
      <hr style={{ margin: "20px 0" }} />
      <div
        style={{
          display: "flex",
          rowGap: "15px",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <ItemBodyItem
          title={"Job Related:"}
          width="100%"
          content={<strong>{event.jobName}</strong>}
        />
        <ItemBodyItem
          title={"Team Assigned:"}
          width="45%"
          content={event.teamName}
        />
        <ItemBodyItem
          title={"Start Date:"}
          width="45%"
          content={event.start_date}
        />
        <ItemBodyItem
          title={"End Date:"}
          width="45%"
          content={event.end_date}
        />
        <ItemBodyItem
          title={"Start Time:"}
          width="45%"
          content={event.start_time}
        />
        <ItemBodyItem
          title={"End Time:"}
          width="45%"
          content={event.end_time}
        />
        <ItemBodyItem
          title={"Notes for the team assigned:"}
          width="100%"
          content={event.notes}
        />
      </div>
    </>
  );
};

export const useAppointments = () => {
  const viewContext = useContext(ViewContext);
  const { appointmentsData, setAppointmentsData } = useContext(MainContext);
  const { allJobsData, allJobsInProgressData, getJobById } = useJobs();
  const { teamsData, getTeamById } = useTeams();

  const [appointmentsEvents, setAppointmentsEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openNewAppointmentModalWhenItsReady, setOpenNewAppointmentModalWhenItsReady] = useState();
  const [openEditAppointmentModalWhenItsReady, setOpenEditAppointmentModalWhenItsReady] = useState();

  const getAppointmentById = (id) => {
    return appointmentsData.find((ap) => ap.id === id);
  };

  const getJobOptions = useCallback(() => {
    return allJobsInProgressData?.map((job) => ({
      value: job.id,
      label: `${job.job_code} (${job.customer.name})`,
    }));
  }, [allJobsInProgressData]);

  const getTeamOptions = useCallback(() => {
    return teamsData?.map((team) => ({
      value: team.id,
      label: team.name,
    }));
  }, [teamsData]);

  useEffect(()=>{
    if(teamsData && allJobsData && openNewAppointmentModalWhenItsReady){
      openNewAppointmentModal();
    } else if (openNewAppointmentModalWhenItsReady) {
      viewContext.modal.show(
        {
          loading: true,
          modalContentClass: "big",
        },
      );
    }
  }, [teamsData, allJobsData, openNewAppointmentModalWhenItsReady]);

  useEffect(()=>{
    if(teamsData && allJobsData && openEditAppointmentModalWhenItsReady){
      viewEvent();
    } else if (openEditAppointmentModalWhenItsReady) {
      viewContext.modal.show(
        {
          loading: true,
          modalContentClass: "big",
        },
      );
    }
  }, [teamsData, allJobsData, openEditAppointmentModalWhenItsReady]);

  const formatDate = date => {
    const dateArray = date.split('-');
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`
  }

  const formatEventDateValues = form => {
    const { start_date, end_date } = form;
    return {
      ...form,
      start_date: formatDate(start_date),
      end_date: formatDate(end_date),
    }
  }


  const getFormattedTime = dateTime => {
    const timeArray = dateTime.split('T')[1].split(':');
    const hourMinuteString = `${timeArray[0]}:${timeArray[1]}`;
    return hourMinuteString;
  }

  const formatEventTimeValues = form => {
    const { start, end } = form;
    return {
      ...form,
      start_time: getFormattedTime(start),
      end_time: getFormattedTime(end),
    }
  }

  const handleCreateNewAppointment = (job_id, beforeCreate, afterCreate) => {
    setOpenNewAppointmentModalWhenItsReady({eventInfo: {job_id}, beforeCreate, afterCreate});
  };

  const openNewAppointmentModal = ()=> {
    const {eventInfo, beforeCreate, afterCreate} = openNewAppointmentModalWhenItsReady;
    setOpenNewAppointmentModalWhenItsReady(null);
    const jobsOptions = getJobOptions();
    const teamsOptions = getTeamOptions();
    viewContext.modal.show(
      {
        loading: false,
        title: "Create a new project",
        formClass: "flex-force flex-wrap justify-between",
        modalContentClass: "big",
        buttonText: "Save",
        form: appointmentForm(teamsOptions, jobsOptions, eventInfo),
      },
      async (form) => {
        if(beforeCreate) beforeCreate();
        const end = dateAndTimeToISOStringConverter(
          form.end_date,
          form.end_time
        );
        const start = dateAndTimeToISOStringConverter(
          form.start_date,
          form.start_time
        );

        if(new Date(end).getTime() < new Date(start).getTime()) {
          return viewContext.notification.show("start date must be earlier than end date!", "error", true);
        }

        const body = {
          ...form,
          end,
          start,
        };

        setLoading(true);
        viewContext.modal.hide();
        
        try {
          await saveNewAppointment(body);
          viewContext.notification.show("Project Created!", "success", true);
          loadAppointments();
          if(afterCreate) afterCreate();
        } catch (err) {
          viewContext.notification.show(err.response?.data?.message || err.toString(), "error", true);
          setOpenNewAppointmentModalWhenItsReady({eventInfo: formatEventDateValues(form), beforeCreate, afterCreate})
        }
        setLoading(false);
      }
    );
  };

  const editEvent = (type, event) => {
    const jobsOptions = getJobOptions();
    const teamsOptions = getTeamOptions();

    if(type === "delete") {
      viewContext.modal.show(
        {
          loading: false,
          title: `Do you want to delete the appointment ${event.name}?`,
          formClass: "flex-force flex-wrap justify-between",
          form: {},
          buttonText: "Delete",
        },
        async () => {
          viewContext.modal.hide();
          setLoading(true);
          await deleteAppointment(event.id);
          setLoading(false);
          loadAppointments();
        }
      );
      return;
    }

    const eventParsed = formatEventTimeValues(event);
    const title =
      type === "schedule" ? "Re-schedule Project" : "Edit Project Info";
    viewContext.modal.show(
      {
        loading: false,
        title,
        formClass: "flex-force flex-wrap justify-between",
        buttonText: "Save",
        form: appointmentForm(teamsOptions, jobsOptions, eventParsed, type),
      },
      async (form) => {
        const data = {
          ...form,
          start: dateAndTimeToISOStringConverter(
            form.start_date,
            form.start_time
          ),
          end: dateAndTimeToISOStringConverter(form.end_date, form.end_time),
        };
        delete data.start_date;
        delete data.start_time;
        delete data.end_date;
        delete data.end_time;

        if(new Date(data.end).getTime() < new Date(data.start).getTime()) {
          return viewContext.notification.show("start date must be earlier than end date!", "error", true);
        }
        viewContext.modal.hide();
        setLoading(true);
        try {
          await putAppointment(event.id, data);
          viewContext.modal.hide();
          viewContext.notification.show("Project Created!", "success", true);
          loadAppointments();
        } catch (err) {
          viewContext.notification.show(err.response?.data?.message || err.toString(), "error", true);
          setOpenEditAppointmentModalWhenItsReady({
            eventInfo: {
              event: {
                ...formatEventDateValues(form),
                id: event.id,
              }
            }})
        }
        setLoading(false);
        loadAppointments();
      }
    );
  };

  const viewEvent = () => {
    const { eventInfo } = openEditAppointmentModalWhenItsReady;
    setOpenEditAppointmentModalWhenItsReady(null);
    const { id } = eventInfo.event;
    const eventToEdit = getAppointmentById(id);
    const jobRelated = getJobById(eventToEdit.job_id);
    eventToEdit.jobName = `${jobRelated?.job_code} (${jobRelated?.customer.name})`;
    eventToEdit.teamName = getTeamById(eventToEdit.team_assigned_id)?.name;
    viewContext.modal.show({
      loading: false,
      customContent: (
        <AppointmentInfoModalContent
          editEvent={editEvent}
          event={eventToEdit}
        />
      ),
    });
  }

  const handleEditEvent = (eventInfo) => {
    setOpenEditAppointmentModalWhenItsReady({eventInfo});
  };

  const parseAndSetAppointmentsToEvent = useCallback(
    (data) => {
      const dataParsed = data
        .filter((ap) => ap.job)
        .map((ap) => {
          const start = dateConverter(ap.start_date, ap.start_time);
          const end = dateConverter(ap.end_date, ap.end_time);
          const multiDays = ap.start_date !== ap.end_date;
          const randomColor =
            "#" + Math.floor(Math.random() * 16777215).toString(16);
          const teamColor = getTeamById(ap.team_assigned_id)?.color;
          const backgroundColor = teamColor || randomColor;
          return {
            title: ap.name,
            category: "time",
            start,
            end,
            calendarId: "0",
            id: ap.id,
            backgroundColor,
            multiDays,
          };
        });
      setAppointmentsEvents(dataParsed);
    },
    [getTeamById]
  );

  const loadAppointments = useCallback(async () => {
    setLoading(true);
    const { data } = await getAllAppointments();
    setAppointmentsData(data);
    parseAndSetAppointmentsToEvent(data);
    setLoading(false);
  }, [parseAndSetAppointmentsToEvent]);

  useEffect(() => {
    if (!appointmentsData) loadAppointments();
    else if (appointmentsEvents.length === 0 || !teamsData)
      parseAndSetAppointmentsToEvent(appointmentsData);
  }, [loadAppointments, parseAndSetAppointmentsToEvent]);

  return {
    loading,
    appointmentsData,
    handleCreateNewAppointment,
    appointmentsEvents,
    handleEditEvent,
  };
};
