/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  Animate,
  Grid,
  Card,
  Icon,
  Link,
  useAPI,
  Loader,
} from "components/lib";
import settings from "../../settings.json";

export function Account(props) {
  const context = useContext(AuthContext);
  const user = useAPI("/api/user");
  const iconSize = 20;

  if (user.loading) return <Loader />;

  return (
    <Animate>
      <Grid cols="4">
        <Card>
          <Icon image="user" size={iconSize} />
          <h2 className={Style.subtitle}>Profile</h2>
          <div>Update your profile information</div>
          <Link url="/account/profile" className={Style.link}>
            Update
          </Link>
        </Card>

        <Card>
          <Icon image="lock" size={iconSize} />
          <h2 className={Style.subtitle}>Password</h2>
          <div>
            {user?.data?.["has_password"]
              ? "Change your password"
              : "Create a password"}
          </div>
          <Link url="/account/password" className={Style.link}>
            {user?.data?.["has_password"] ? "Change" : "Create"}
          </Link>
        </Card>

        {context.permission?.owner && (
          <Card>
            <Icon image="credit-card" size={iconSize} />
            <h2 className={Style.subtitle}>Billing</h2>
            <div>Update your plan or credit card</div>
            <Link url="/account/billing" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}

        {context.permission?.developer && (
          <Card>
            <Icon image="settings" size={iconSize} />
            <h2 className={Style.subtitle}>API Keys</h2>
            <div>Manage your API keys</div>
            <Link url="/account/apikeys" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="users" size={iconSize} />
            <h2 className={Style.subtitle}>Users</h2>
            <div>Invite users to your account</div>
            <Link url="/account/users" className={Style.link}>
              Add users
            </Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="briefcase" size={iconSize} />
            <h2 className={Style.subtitle}>Company</h2>
            <div>Edit company info</div>
            <Link url="/account/company" className={Style.link}>
              Edit
            </Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="refresh-cw" size={iconSize} />
            <h2 className={Style.subtitle}>Trade Growth Sync</h2>
            <div>Sync your account with Trade Growth</div>
            <Link
              text="Sync"
              url={settings.production.GoHighLevelSync}
              className={Style.link}
            >
              Sync
            </Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="share-2" size={iconSize} />
            <h2 className={Style.subtitle}>Invite Employees</h2>
            <div>
              Invite all of your employees from Trade Growth platform to Job
              Estimate App.
            </div>
            <Link text="Sync" url="/invite-employees" className={Style.link}>
              Invite
            </Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="users" size={iconSize} />
            <h2 className={Style.subtitle}>Teams</h2>
            <div>Manage Teams.</div>
            <Link text="Sync" url="/teams" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}
      </Grid>
    </Animate>
  );
}

const Style = {
  subtitle: "font-bold mt-4",
  link: "text-blue-500 font-semibold mt-3 inline-block",
};
