/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import { Fragment, useContext } from "react";
import { AuthContext, AppNav, Header, User } from "components/lib";
import Style from "./app.module.scss";

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);

  return (
    <Fragment>
      <AppNav
        items={[
          {
            label: "Dashboard",
            icon: "bar-chart-2",
            link: "/dashboard",
            permission: "admin",
          },
          { label: "Estimates", icon: "clipboard", link: "/estimates" },
          { label: "Jobs", icon: "briefcase", link: "/jobs" },
          { label: "Schedules", icon: "calendar", link: "/schedules" },
          { label: "Timesheet", icon: "clock", link: "/timesheet" },
          {
            label: "Pricebook",
            icon: "book",
            link: "/pricebook",
            permission: "admin",
          },
          {
            label: "Profitability",
            icon: "dollar-sign",
            link: "/profitability",
            permission: "admin",
          },
        ]}
        bottomItems={[
          { label: "Account", icon: "user", link: "/account" },
          { label: "Sign Out", icon: "log-out", action: context.signout },
        ]}
      />

      <main className={Style.app}>
        <Header title={props.title}>
          <User />
        </Header>

        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}
