import { Icon } from 'components/lib'
import React from 'react'

const AlreadyClocked = ({
  clockType,
  clockedTime,
}) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0.5em',
      border: '0.2em solid #f0f0f0',
      borderRadius: '0.5em',
      marginBottom: '1em',
      color: '#a8a8a8',
    }}>
      <div style={{
        display: 'flex',
        gap: '1em',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.2em',
        fontWeight: 'bold',
        marginBottom: '0.2em',
      }}>
        {clockType}
        <Icon
          image='check'
          size='28'
        />
      </div>
      <div>
        {clockedTime}
      </div>
    </div>
  )
}

export default AlreadyClocked