import { Card, FlexContainer, Table, ClockInOrOutComponent, SearchSelect } from 'components/lib'
import useJobs from 'hooks/useJobs'
import useTimesheet from 'hooks/useTimesheet'
import React from 'react'

const Timesheet = () => {
  const {
    jobOptions,
    loadingJobs,
  } = useJobs();
  const {
    loadingTimesheet,
    timesheetTable,
    changeSelectedJob,
    clockInOrOut,
    disabledButtons,
    isAdmin,
    jobsPresentsInTimesheetOptions,
    workersPresentsInTimesheetOptions,
    filterTimesheet,
    loadingAvailability,
    clockedInTime,
    selectedJob,
  } = useTimesheet();
  
  return (
    <Card loading={(loadingTimesheet || loadingJobs)}>
      <FlexContainer gap='4em'>
        { (!isAdmin) &&
          <ClockInOrOutComponent
            changeSelectedJob={changeSelectedJob}
            clockInOrOut={clockInOrOut}
            disabledButtons={disabledButtons}
            jobOptions={jobOptions}
            loadingAvailability={loadingAvailability}
            clockedInTime={clockedInTime}
            selectedJob={selectedJob}
          />
        }
        <div className='w-full'>
          { isAdmin && <FlexContainer width='100%' gap='1em' justifyContent='end' flexWrap='wrap'>
            <SearchSelect
              name='job'
              width='10em'
              placeholder='Filter by Job'
              options={jobsPresentsInTimesheetOptions}
              onChange={filterTimesheet}
              isMulti
            />
            <SearchSelect
              name='employee'
              width='10em'
              placeholder='Filter by Worker'
              options={workersPresentsInTimesheetOptions}
              onChange={filterTimesheet}
              isMulti
            />
          </FlexContainer>}
          <Table
            loading={loadingTimesheet}
            data={timesheetTable}
            show={
              isAdmin ? ["employeeName", "clock_in", "clock_out", "totalTime"]
              : ["jobName", "clock_in", "clock_out"]
            }
          />
        </div>
      </FlexContainer>
    </Card>
  )
}

export default Timesheet