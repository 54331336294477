import React from 'react'

const AppIcon = () => {
  return (
    <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.0212 0V28.8791H38.9644C38.8526 27.4999 38.569 26.1387 38.1197 24.8248C37.922 24.2433 37.6913 23.6728 37.4286 23.1157C37.3756 23.0012 37.3208 22.8886 37.2639 22.7753C36.8029 21.844 36.2563 20.9543 35.6308 20.1167C35.5204 19.9701 35.4081 19.8235 35.2931 19.68C35.0632 19.3931 34.8236 19.113 34.5745 18.8399C34.4511 18.704 34.3256 18.5687 34.1963 18.4366C34.0669 18.3044 33.935 18.1729 33.8043 18.0446C33.739 17.9816 33.6737 17.9187 33.6044 17.8558C33.1337 17.4121 32.6382 16.9935 32.1203 16.6017C31.9726 16.4903 31.8224 16.3796 31.6708 16.272C31.5944 16.2185 31.518 16.165 31.4415 16.1127C30.7484 15.6371 30.021 15.2096 29.2649 14.8335V5.50348L39.0212 0Z"
        fill="url(#paint0_linear_534_74)"/>
      <path
        d="M42 31.4383C42 31.7747 41.9312 32.1078 41.7976 32.4186C41.6639 32.7294 41.468 33.0118 41.2211 33.2497C40.9741 33.4876 40.681 33.6763 40.3583 33.805C40.0357 33.9337 39.6899 34 39.3406 34H2.65937C1.95406 34 1.27764 33.7301 0.778911 33.2497C0.280181 32.7693 0 32.1177 0 31.4383C0 30.7589 0.280181 30.1073 0.778911 29.6269C1.27764 29.1465 1.95406 28.8766 2.65937 28.8766H3.03565C3.53538 22.7489 7.33925 17.5179 12.7338 14.8303V28.8791H16.1255V12.9205L25.8759 7.41579V28.8791H29.2675V14.8278C30.0234 15.2034 30.7505 15.6303 31.4435 16.1052C31.5199 16.1574 31.5964 16.2109 31.6728 16.2644C31.8243 16.372 31.9746 16.4828 32.1222 16.5941C32.6407 16.9865 33.1366 17.4058 33.6077 17.8501C33.673 17.913 33.7384 17.976 33.8076 18.0389C33.9383 18.1673 34.0689 18.2979 34.1996 18.4309C34.3302 18.5631 34.4537 18.6984 34.5778 18.8343C34.8251 19.1074 35.0647 19.3874 35.2964 19.6743C35.4113 19.8191 35.5239 19.9646 35.6341 20.111C36.2596 20.9486 36.8062 21.8383 37.2672 22.7697C37.3238 22.8821 37.3787 22.9956 37.4318 23.1101C37.6946 23.6671 37.9253 24.2377 38.123 24.8191C38.5716 26.135 38.8541 27.4982 38.9644 28.8791H39.34C40.0453 28.8791 40.7217 29.149 41.2204 29.6294C41.7192 30.1098 41.9993 30.7614 41.9993 31.4408L42 31.4383Z"
        fill="url(#paint1_linear_534_74)"/>
      <defs>
        <linearGradient id="paint0_linear_534_74" x1="45.4281" y1="34.0985" x2="41.7756" y2="-20.5693" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00FFB4"/>
          <stop offset="1" stopColor="#0F2FFF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_534_74" x1="4.18014" y1="38.6005" x2="37.1328" y2="15.8686" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00FFB4"/>
          <stop offset="1" stopColor="#0F2FFF"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default AppIcon


