import { getContrastColor } from "lib";
import { useEffect, useState } from "react";
import { TextInput } from "../text";

const TeamColor = props=>{
    const [color, setColor] = useState('black');
    useEffect(()=>{
        setColor(getContrastColor(props.value || '#000000'));
    }, [props.value]);
    
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}
        >
            <TextInput
                {...props}
            />
            <div
                style={{
                    backgroundColor: props.value || 'black',
                    color: color,
                    height: 'fit-content',
                    width: '70%',
                    position: 'relative',
                    top: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 'bold'
                }}
            >
                {props.teamName || 'Example text'}
            </div>
        </div>
    )
}

export default TeamColor