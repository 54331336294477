import { ViewContext } from "components/lib";
import { MainContext } from "contexts/MainContext";
import {
  deleteItem,
  getEquipments,
  getItems,
  getItemsCollections,
  getItemTypes,
  patchQuantity,
  postItem,
  putItem,
} from "lib/services";
import { useCallback, useContext, useEffect, useState } from "react";
import { costPerOptions, itemExtendedForm } from "./lib";
import { moneyFormatter } from "lib";
const header = [
  { name: "accountId", title: "accountId", sort: true },
  { name: "id", title: "id", sort: true },
  { name: "itemName", title: "Name", sort: true },
  { name: "itemType", title: "Type", sort: true },
  { name: "itemQuantity", title: "Quantity", sort: true },
  { name: "costPer", title: "Price Per:", sort: true },
  { name: "itemPrice", title: "Price", sort: true },
  { name: "itemCost", title: "Item Cost:", sort: true },
  { name: "itemDescription", title: "Description", sort: true },
];
const equipmentHeader = [
  { name: "name", title: "Name", sort: true },
  { name: "hourly_rate", title: "Hourly Rate", sort: true },
  { name: "daily_rate", title: "Daily Rate", sort: true },
  { name: "description", title: "Description", sort: true },
];
const useItems = () => {
  const viewContext = useContext(ViewContext);
  const {
    inventoryItems,
    setInventoryItems,
    equipments,
    setEquipments,
    itemsCollection,
    setItemsCollection,
    itemTypes,
    setItemTypes,
    loadingItems,
    setLoadingItems,
  } = useContext(MainContext);
  const [itemsFiltered, setItemsFiltered] = useState();
  const [itemsTable, setItemsTable] = useState({});
  const [collectionsTables, setCollectionsTables] = useState();
  const [equipmentsTable, setEquipmentsTable] = useState();
  const [itemsOptions, setItemsOptions] = useState();
  
  const filterItems = (text) => {
    const items = inventoryItems?.filter((item) =>
      item.itemName.toLowerCase().includes(text.toLowerCase().trim())
    );
    setItemsFiltered(text ? items : null);
  };

  const getItemTypeById = useCallback((id)=>{
    return itemTypes?.find(itemType => itemType.value === id);
  }, [itemTypes])

  const getItemTypeByName = useCallback((name)=>{
    return itemTypes?.find(itemType => itemType.label === name);
  }, [itemTypes])

  const getCostPerOptionsById = id => {
    return costPerOptions.find((option) => option.value === id);
  }

  const loadItemTypes = useCallback(async () => {
    const data = await getItemTypes();
    const processedData = data
      ? data.map((type) => ({ value: type.id, label: type.name }))
      : [];
    setItemTypes(processedData);
    return processedData;
  }, [setItemTypes]);

  const reload = useCallback(async () => {
    setLoadingItems(true);
    const types = await loadItemTypes();
    if (!types.length) {
      viewContext.handleError("No item types. Please contact support.");
    }
    const rawItems = await getItems();
    const rawCollections = await getItemsCollections();
    const rawEquipments = await getEquipments();
    setLoadingItems(false);

    const convertItem = item => ({
      accountId: item.account_id,
      id: item.id,
      itemName: item.item_name,
      itemType: types.find((type) => type.value === item.item_type_id),
      itemQuantity: item.quantity_in_stock,
      costPer: getCostPerOptionsById(item.cost_per),
      itemPrice: item.item_price,
      profit_percentage: item.profit_percentage,
      itemCost: item.item_cost,
      itemDescription: item.description,
    })
    const itemsData = rawItems.map(convertItem);
    const collectionsData = rawCollections.map(collection => ({
      ...collection,
      items: collection.items.map(convertItem)
    }));
    setInventoryItems(itemsData);
    setItemsOptions(itemsData.map(item => ({
      ...item,
      value: item.id,
      label: item.itemName,
    })))
    setItemsCollection(collectionsData);

    const convertItemToTableRow = item => ({
      ...item,
      itemType: item.itemType.label,
      costPer: item.costPer.label,
      itemPrice: moneyFormatter(item.itemPrice),
    })
    const itemsDataForTable = itemsData.map(convertItemToTableRow);
    const collectionsDataForTables = collectionsData.map(collection => ({
      ...collection,
      items: collection.items.map(convertItemToTableRow),
    }))

    setEquipments(rawEquipments);
    setEquipmentsTable({body: rawEquipments, header: equipmentHeader})
    setCollectionsTables(collectionsDataForTables);
    setItemsTable({ body: itemsDataForTable, header });
  }, [setLoadingItems, setInventoryItems, loadItemTypes, setItemsCollection]);

  useEffect(() => {
    reload();
  }, [reload]);

  const handleAddQuantity = (item) => {
    viewContext.modal.show(
      {
        title: "Add Quantity",
        form: {
          quantity: {
            type: "number",
            required: true,
            label: "Quantity",
          },
        },
        buttonText: "Save",
      },
      async (data) => {
        await patchQuantity(item.id, data.quantity);
        reload();
        viewContext.modal.hide();
      }
    );
  };

  const handleDeleteItem = (item) => {
    const { id } = item;
    const itemFound = getItem(id);
    viewContext.modal.show(
      {
        title: `Do you want to delete ${itemFound.itemName}?`,
        form: {},
        destructive: true,
        buttonText: "Delete",
      },
      async () => {
        await deleteItem(id);
        reload();
        viewContext.modal.hide();
      }
    );
  };

  const handleCreateItem = async (item) => {
    viewContext.modal.show(
      {
        title: `Add Inventory Item`,
        modalContentClass: "full-width",
        formClass: "flex-force flex-wrap justify-between",
        form: itemExtendedForm(itemTypes, item),
        buttonText: "Save",
      },
      async (form) => {
        setLoadingItems(true);
        viewContext.modal.hide();
        try {
          await postItem(form);
          reload();
          viewContext.modal.hide();
        } catch (err) {
          const msg = err.response?.data?.message ?? err.toString();
          viewContext.notification.show(msg || "An error occurred", "error", true);
          handleCreateItem(form);
        }
        setLoadingItems(false);
      }
    );
  };
  const getPricePerByName = (name) => {
    return costPerOptions.find((costPer) => costPer.label === name);
  };
  const getItem = (itemId) => {
    return inventoryItems.find((item) => item.id === itemId);
  };

  const handleEditItem = async (item) => {
    const itemFound = item ?? getItem(item.id);

    //these parsings bellow are because "item" could come from the getItem function, or previous failed request, or directly from the table.

    //cost per and item type could come only as a text, in which case we need to get the full-object option selected.
    if(!itemFound.costPer.value) {
      itemFound.costPer = getPricePerByName(itemFound.costPer);
    }
    if(!itemFound.itemType.value) {
      itemFound.itemType = getItemTypeByName(itemFound.itemType);
    }

    //price, cost, and profit could come in different ways, and price should not go to the form with the $ symbol.
    const price = item.itemPrice?.toString()?.split('$') || item.itemCostProfitPrice.item_price;
    itemFound.itemCost = itemFound.itemCost ?? itemFound.itemCostProfitPrice.item_cost;
    itemFound.profit_percentage = itemFound.profit_percentage ?? itemFound.itemCostProfitPrice?.profit_percentage;
    itemFound.itemPrice = price[1] ?? price[0];

    viewContext.modal.show(
      {
        title: `Edit Item Information`,
        modalContentClass: "full-width",
        formClass: "flex-force flex-wrap justify-between",
        form: itemExtendedForm(itemTypes, itemFound),
        buttonText: "Save",
      },
      async (form) => {
        setLoadingItems(true);
        viewContext.modal.hide();
        try {
          await putItem(item.id, form);
          reload();
          viewContext.notification.show("Item saved successfully!", "success", true);
          viewContext.modal.hide();
        } catch (err) {
          const msg = err.response?.data?.message ?? err.toString();
          viewContext.notification.show(msg || "An error occurred", "error", true);
          form.costPer = getCostPerOptionsById(form.costPer);
          form.itemType = getItemTypeById(form.itemType);
          handleEditItem({
            ...form,
            id: item.id,
          });
        }
        setLoadingItems(false);
      }
    );
  };

  const getItemsOptions = useCallback(items=>{
    return items?.map(item=> ({value: item.id, label: item.itemName}));
  }, []);

  const getAllItemsOptions = useCallback(() => {
    return getItemsOptions(inventoryItems);
  }, [inventoryItems, getItemsOptions])

  const openCollectionModal = (method, title, collection) => {
    const itemsOptions = getAllItemsOptions();
    const itemsAlreadyInCollection = collection?.items.map(item => item.id);
    let url = `/api/collections`;
    if(method === 'PUT') {
      url += '/' + collection.id;
    }
    viewContext.modal.show(
      {
        title: title,
        modalContentClass: '!overflow-visible',
        formClass: "flex-force flex-wrap justify-between",
        url,
        method: method,
        form: {
          items: {
            type: "multiselect",
            required: true,
            options: itemsOptions,
            label: "Items",
            containerClass: "w-full",
            value: itemsAlreadyInCollection
          },
          name: {
            type: "text",
            required: true,
            label: "Collection Name",
            containerClass: "w-full",
            value: collection?.name,
          },
        },
        buttonText: "Save",
      },
      async () => {
        const message = method === 'POST' ? 'New collection created!' : 'Collection updated!';
        viewContext.notification.show(message, 'success', true);
        reload();
      }
    );
  }

  const handleCreateCollection = ()=>{
    openCollectionModal('POST', `Create Collection`);
  }

  const handleEditCollection = (collection)=>{
    openCollectionModal('PUT', `Edit Collection`, collection);
  }

  const handleDeleteCollection = (collection)=>{
    viewContext.modal.show(
      {
        title: `Do you want to delete collection '${collection.name}'?`,
        formClass: "flex-force flex-wrap justify-between",
        url: `/api/collections/` + collection.id,
        method: 'DELETE',
        form: {},
        destructive: true,
        buttonText: "Delete",
      }, async () => {
        viewContext.notification.show('Collection deleted successfully!', 'success', true);
        reload();
      }
    );
  }
  
  const openEquipmentModal = (method, title, equipment) => {
    let url = `/api/equipment`;
    if(method === 'PUT') {
      url += '/' + equipment.id;
    }
    viewContext.modal.show(
      {
        title: title,
        formClass: "flex-force flex-wrap justify-between",
        url,
        method: method,
        form: {
          name: {
            type: "text",
            required: true,
            label: "Equipment Name",
            containerClass: "w-full",
            value: equipment?.name,
          },
          hourly_rate: {
            prefix: '$',
            type: "number",
            required: true,
            label: "Hourly Rate",
            containerClass: "w-full",
            value: equipment?.hourly_rate,
          },
          daily_rate: {
            prefix: '$',
            type: "number",
            required: true,
            label: "Daily Rate",
            containerClass: "w-full",
            value: equipment?.daily_rate,
          },
          description: {
            type: "text",
            required: true,
            label: "Equipment Description",
            containerClass: "w-full",
            value: equipment?.description,
          },
        },
        buttonText: "Save",
      },
      async () => {
        const message = method === 'POST' ? 'New equipment created!' : 'Equipment updated!';
        viewContext.notification.show(message, 'success', true);
        reload();
      }
    );
  }

  const handleCreateEquipment = ()=>{
    openEquipmentModal('POST', `Create Equipment`);
  }

  const handleEditEquipment = (equipment)=>{
    openEquipmentModal('PUT', `Edit Equipment`, equipment);
  }

  const handleDeleteEquipment = (equipment)=>{
    viewContext.modal.show(
      {
        title: `Do you want to delete equipment '${equipment.name}'?`,
        formClass: "flex-force flex-wrap justify-between",
        url: `/api/equipment/` + equipment.id,
        method: 'DELETE',
        form: {},
        destructive: true,
        buttonText: "Delete",
      }, async () => {
        viewContext.notification.show('Equipment deleted successfully!', 'success', true);
        reload();
      }
    );
  }

  return {
    handleAddQuantity,
    handleDeleteItem,
    handleCreateItem,
    handleEditItem,
    inventoryItems,
    itemsCollection,
    collectionsTables,
    equipmentsTable,
    equipments,
    itemTypes,
    loadingItems,
    filterItems,
    itemsFiltered,
    itemsTable,
    itemsOptions,
    getItemTypeById,
    handleCreateCollection,
    handleEditCollection,
    handleDeleteCollection,
    handleCreateEquipment,
    handleEditEquipment,
    handleDeleteEquipment,
  };
};

export default useItems;
