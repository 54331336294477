import React from 'react'

const FlexContainer = ({
  alignItems,
  flexDirection,
  justifyContent,
  width,
  height,
  minWidth,
  children,
  margin,
  marginTop,
  marginBottom,
  marginRight,
  marginLeft,
  marginY,
  marginX,
  gap,
  flexWrap,
  rowGap,
  columnGap,
  className
}) => {
  const styles = {
    minWidth,
    width,
    height,
    flexDirection,
    display: 'flex',
    justifyContent,
    alignItems,
    margin,
    marginTop: marginTop || marginY,
    marginBottom: marginBottom || marginY,
    marginRight: marginRight || marginX,
    marginLeft: marginLeft || marginX,
    gap,
    flexWrap,
    rowGap,
    columnGap,
  }
  if(!rowGap) delete styles.rowGap;
  if(!columnGap) delete styles.columnGap;
  return (
    <div
      style={styles}
      className={className}
    >
      {children}
    </div>
  )
}

export default FlexContainer