import React from 'react'
import { NumberInput } from '../number';

const ShowCostPriceAndEditPercentage = (props) => {
  const cleanProfit = props.value ? props.value.toString().split('%')[0] : 0;
  return (
    <div className='flex mb-4 justify-between w-full'>
      <div className='flex flex-col justify-end mb-3'>
        <div>Item Cost</div>
        <div className='font-bold text-xl'>U$ {props.cost}</div>
      </div>
      <NumberInput
        {...props}
        value={cleanProfit}
      />
      <div className='flex flex-col justify-end mb-3'>
        <div>Item Price</div>
        <div className='font-bold text-xl'>U$ {(props.getPriceByCostAndProfitPercentage(props.cost, props.value))}</div>
      </div>
    </div>
  )
}

export default ShowCostPriceAndEditPercentage