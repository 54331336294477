/***
 *
 *   BLANK LAYOUT
 *   Main website layout
 *
 **********/

import { Fragment } from "react";

export function BlankLayout(props) {
  return (
    <Fragment>
      <main className="home">{<props.children {...props.data} />}</main>
    </Fragment>
  );
}
