import { MainContext } from 'contexts/MainContext'
import { useCallback, useContext, useEffect, useState } from 'react'
import { clockInOrOut as postClockInOrOut, getTimesheet, getClockInOrOutAvailability } from './../lib/services/index'
import { AuthContext, ViewContext } from 'components/lib';
import { ISOStringToLocaleString, calculateTimeFromTimeToTime } from './lib';

const header = [
  { name: "jobName", title: "Job", sort: true },
  { name: "employeeName", title: "Worker", sort: true },
  { name: "clock_in", title: "Start Date and Time", sort: true },
  { name: "clock_out", title: "End Date and Time", sort: true },
  { name: "totalTime", title: "Total", sort: true },
];

const useTimesheet = () => {
  const {
    timesheet,
    setTimesheet,
  } = useContext(MainContext);
  const {permission: {admin}} = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const [loadingTimesheet, setLoadingTimesheet] = useState(false);
  const [timesheetTable, setTimesheetTable] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [disabledButtons, setDisabledButtons] = useState({
    clockin: false,
    clockout: false,
  });
  const [jobsPresentsInTimesheetOptions, setJobsPresentsInTimesheetOptions] = useState([]);
  const [workersPresentsInTimesheetOptions, setWorkersPresentsInTimesheetOptions] = useState([]);
  const [filters, setFilters] = useState({job: [], employee: []});
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [clockedInTime, setClockedInTime] = useState('');

  const checkAndSetAvailability = async selectedJob => {
    setLoadingAvailability(true)
    const { data: {action_available, action_date} } = await getClockInOrOutAvailability(selectedJob);
    setDisabledButtons({
      clockin: action_available !== 'clock_in',
      clockout: action_available !== 'clock_out',
    })
    const clockin = ISOStringToLocaleString(action_date);
    setClockedInTime(clockin);
    setLoadingAvailability(false);
  }

  useEffect(()=>{
    if(!selectedJob || selectedJob === 'unselected') {
      setDisabledButtons({
        clockin: true,
        clockout: true,
      })
    } else {
      checkAndSetAvailability(selectedJob);
    }
  }, [selectedJob])

  const clockInOrOut = async ([action, text]) => {
    setLoadingTimesheet(true);
    try {
      await postClockInOrOut(selectedJob, action);
      setSelectedJob(null);
      setClockedInTime(null);
    } catch (e) {
      console.log(e);
      viewContext.notification.show(`You cannot ${text} for this job right now`, 'error', false)
    } finally {
      reload();
    }
  }

  const changeSelectedJob = (_, value) => {
    setSelectedJob(value)
  }

  useEffect(()=>{
    if(timesheet) {
      const timesheetFiltered = timesheet.filter(row => Object.keys(filters).reduce((prev, filterName) => {
        return filters[filterName].length ? 
          filters[filterName].find(f => f.value === row[filterName]?.id)
          : prev;
      }, true))
      const body = timesheetFiltered.map(timesheetMapper)
      setTimesheetTable({body, header});
    }
  },[filters])

  const filterTimesheet = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  const timesheetMapper = ({employee, customer, job, clock_in, clock_out}) => ({
    employeeName: employee.name,
    jobName: `${customer?.fullNameLowerCase} - ${job?.job_code}`,
    clock_in: clock_in && ISOStringToLocaleString(clock_in),
    clock_out: clock_out && ISOStringToLocaleString(clock_out),
    totalTime: clock_in && clock_out && calculateTimeFromTimeToTime(clock_in, clock_out),
  })

  const reload = useCallback(async () => {
    setLoadingTimesheet(true);
    const timesheetData = await getTimesheet();
    setTimesheet(timesheetData);
    const body = timesheetData.map(timesheetMapper)
    const jobsAndWorkers = timesheetData.reduce((prev, cur) => {
      if(cur.employee && !prev.workers.map(worker => worker.value).includes(cur.employee.id)) {
        prev.workers.push({
          value: cur.employee.id,
          label: cur.employee.name,
        });
      }
      if(cur.job && !prev.jobs.map(job => job.value).includes(cur.job.id)) {
        prev.jobs.push({
          value: cur.job.id,
          label: `${cur.customer?.fullNameLowerCase} - ${cur.job?.job_code}`,
        });
      }
      return prev;
    }, {jobs: [], workers: []});
    setJobsPresentsInTimesheetOptions(jobsAndWorkers.jobs)
    setWorkersPresentsInTimesheetOptions(jobsAndWorkers.workers)
    setTimesheetTable({body, header});
    setLoadingTimesheet(false);
  }, [])

  useEffect(()=>{
    reload();
  },[reload])

  return {
    timesheetTable,
    loadingTimesheet,
    changeSelectedJob,
    clockInOrOut,
    disabledButtons,
    isAdmin: admin,
    jobsPresentsInTimesheetOptions,
    workersPresentsInTimesheetOptions,
    filterTimesheet,
    loadingAvailability,
    clockedInTime,
    selectedJob,
  }
}

export default useTimesheet