import React from 'react'
import ItemBodyItem from './itemBodyItem';

const EquipmentBody = ({values: {
  description,
  daily_rate,
  hourly_rate,
  timeNeeded,
  timeType,
  totalCost,
}}) => {
  const wording = {
    'hours_needed': {
      timeNeeded: 'Hours needed',
      rate: {
        label: 'Hourly Rate',
        value: hourly_rate
      },
    },
    'days_needed': {
      timeNeeded: 'Days needed',
      rate: {
        label: 'Daily Rate',
        value: daily_rate
      },
    }
  }
  return (
    <div style={{display: 'flex', rowGap: '15px', flexWrap: 'wrap', justifyContent: 'space-between'}}>
      <ItemBodyItem title={wording[timeType].timeNeeded} width='45%' content={timeNeeded}/>
      <ItemBodyItem title={wording[timeType].rate.label} width='45%' content={wording[timeType].rate.value}/>
      <ItemBodyItem title='Description:' width='45%' content={description}/>
      <ItemBodyItem title='Total Cost:' width='45%' content={totalCost}/>
    </div>
  )
}

export default EquipmentBody