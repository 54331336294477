import { Button, Card, FlexContainer, Header, Icon } from "components/lib";
import useViewJob from "hooks/useViewJob";
import React from "react";

const MainInfo = ({
  jobId,
  serviceName,
  serviceAddress,
  servicePhone,
  serviceEmail,
  billToName,
  billToAddress,
  billToPhone,
  billToEmail,
}) => {
  const { editServiceLocation, editBillTo } = useViewJob(jobId);

  return (
    <Card>
      <FlexContainer width={"100%"}>
        <FlexContainer width={"50%"} flexDirection={"column"}>
          <Header className="mb-0" title="Service Location" />
          <div className="edit-service-location">
            <Button action={editServiceLocation} icon="edit" />
          </div>
          <FlexContainer flexDirection={"column"} gap={"1.2em"}>
            <FlexContainer columnGap="0.5em">
              <Icon className="mt-1" image="user" /> {serviceName}
            </FlexContainer>
            <FlexContainer columnGap="0.5em">
              <Icon className="mt-1" image="map-pin" /> {serviceAddress}
            </FlexContainer>
            {servicePhone && (
              <FlexContainer columnGap="0.5em">
                <Icon className="mt-1" image="phone" /> {servicePhone}
              </FlexContainer>
            )}
            <FlexContainer columnGap="0.5em">
              <Icon className="mt-1" image="mail" /> {serviceEmail}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer width={"50%"} flexDirection={"column"}>
          <Header className="mb-0" title="Bill To" />
          <div className="edit-bill-to">
            <Button action={editBillTo} icon="edit" />
          </div>
          <FlexContainer flexDirection={"column"} gap={"1.2em"}>
            <FlexContainer columnGap="0.5em">
              <Icon className="mt-1" image="user" /> {billToName}
            </FlexContainer>
            <FlexContainer columnGap="0.5em">
              <Icon className="mt-1" image="map-pin" /> {billToAddress}
            </FlexContainer>
            {billToPhone && (
              <FlexContainer columnGap="0.5em">
                <Icon className="mt-1" image="phone" /> {billToPhone}
              </FlexContainer>
            )}
            <FlexContainer columnGap="0.5em">
              <Icon className="mt-1" image="mail" /> {billToEmail}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Card>
  );
};

export default MainInfo;
