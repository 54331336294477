import { Button, Card, Header } from 'components/lib'
import React from 'react'
import Appointment from './appointment'

const Appointments = ({appointments, addAppointment}) => {
  return (
    <Card>
      <Header>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <h1>Scheduled Projects</h1>
          <Button
            text='Add Project'
            color='blue'
            action={addAppointment}
          />
        </div>
      </Header>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1em',
        }}
      >
        { appointments?.length?
          appointments?.map((appointment, index)=> <Appointment key={index} appointment={appointment}/>)
          : <>No sheduled projects to show</>
        }
      </div>
    </Card>
  )
}

export default Appointments