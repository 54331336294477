import React, { useEffect, useState } from 'react'
import { NumberInput } from '../number';

const ItemCostProfitPrice = (props) => {
  const cleanProfit = props.value.profit_percentage ? props.value.profit_percentage.split('%')[0] : 0;
  const [cost, setCost] = useState(props.value.item_cost || 0);
  const [profit, setProfit] = useState(cleanProfit || 0);
  const [price, setPrice] = useState(props.value.item_price || 0);

  const onChange = (inputName, value, valid) => {
    const valueParsed = parseFloat(value);
    const newValue = (!isNaN(valueParsed)) ? valueParsed : 0;
    let newCostValue = parseFloat(cost);
    let newProfitValue = parseFloat(profit);
    let newPriceValue = parseFloat(price);
    switch (inputName) {
      case 'cost': {
        setCost(newValue);
        const profitPerOne = parseFloat(newProfitValue) / 100;
        const newPrice = newValue + (newValue * profitPerOne);
        setPrice(newPrice.toFixed(2));
      } break;
      case 'profit': {
        setProfit(newValue);
        const profitPerOne = parseFloat(newValue) / 100;
        const newPrice = newCostValue + (newCostValue * profitPerOne);
        setPrice(newPrice.toFixed(2));
      } break;
      case 'price': {
        setPrice(newValue);
        const newProfit = 100 * ((newValue - newCostValue) / newCostValue);
        setProfit(newProfit.toFixed(2));
      } break;
    }
    props.onChange?.(props.name, {
      item_cost: newCostValue.toString(),
      profit_percentage: newProfitValue.toString(),
      item_price: newPriceValue.toString(),
    }, true);
  }

  return (
    <div className='flex gap-x-4 w-full'>
      <NumberInput
        label='Item Cost ($)'
        required
        name='cost'
        value={cost}
        decimals
        containerClass='w-[33%]'
        prefix='$'
        onChange={onChange}
      />
      <NumberInput
        label='Profit Percentage'
        required
        min={0}
        name='profit'
        value={profit}
        decimals
        containerClass='w-[33%]'
        sufix='%'
        onChange={onChange}
      />
      <NumberInput
        label='Item Price ($)'
        required
        name='price'
        value={price}
        decimals
        containerClass='w-[33%]'
        prefix='$'
        onChange={onChange}
      />
    </div>
  )
}

export default ItemCostProfitPrice