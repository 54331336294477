import FlexContainer from "components/flexcontainer/flexContainer";
import { Animate, Button, Card } from "components/lib";
import React from "react";
import { useAppointments } from "hooks/useAppointments";
import { CalendarApp } from "./../../components/toastui-calendar/app";
import useTeams from "hooks/useTeams";

const Appointments = () => {
  const { loading, handleCreateNewAppointment, appointmentsEvents, handleEditEvent } =
    useAppointments();
  const { createTeam } = useTeams();
  return (
    <Animate type="pop">
      <FlexContainer justifyContent={"flex-end"} gap={10} marginY={10}>
        <Button
          permission="admin"
          text={"New Team"}
          color="blue"
          className="px-5"
          action={createTeam}
        />
        <Button
          text={"New Project"}
          color="blue"
          permission="admin"
          className="px-5"
          action={handleCreateNewAppointment}
        />
      </FlexContainer>

      <Card loading={loading}>
        {!loading && (
          <CalendarApp
            view="month"
            onEditEvent={handleEditEvent}
            events={appointmentsEvents}
          />
        )}
      </Card>
    </Animate>
  );
};

export default Appointments;
