import { useState, useEffect } from "react";
import useJobs from "./useJobs";
import useItems from "./useItems";
import { useAppointments } from "./useAppointments";
import { useFilesViewer } from "./useFilesViewer";
import useTeams from "./useTeams";
import { parseTabNumberToJobHistoryLogCategory } from "./lib";
import { formatDate } from "helpers/dateFormatter";

const historyLogHeader = [
  { name: "message", title: "Log", sort: true },
  { name: "dateCreated", title: "Date and Time", sort: true },
];

const useViewJob = (jobId) => {
  const { getJobById, handleEditBillTo, handleAddInformationToJob, handleEditServiceLocation, reload, loadingJobs } = useJobs();
  const { handleCreateNewAppointment } = useAppointments();

  const { getFilesTable } = useFilesViewer();

  const { getTeamById } = useTeams();

  const { getItemTypeById } = useItems();
  const [jobInfo, setJobInfo] = useState();
  const [filesTable, setFilesTable] = useState();
  const [jobLogsTable, setJobLogsTable] = useState();
  const clearJob = async () => {
    setJobInfo(null);
  };
  const addAppointment = () => {
    handleCreateNewAppointment(jobId, clearJob, reload);
  };
  const addNote = () => {
    handleAddInformationToJob({ id: jobId }, reload);
  };
  const fillFilesTable = async (info) => {
    const files = await getFilesTable(info);
    setFilesTable(files);
  };

  const editServiceLocation = () => {
    handleEditServiceLocation(getJobById(jobId), clearJob);
  };

  const editBillTo = () => {
    handleEditBillTo(getJobById(jobId), clearJob);
  };

  const switchHistoryLogTab = (tab) => {
    const info = getJobById(jobId);
    if (info) {
      fillJobLogsTable(info, tab);
    }
  };

  const filterHistoryLogs = (historyLogs, tab) => {
    let historyLogsFiltered = historyLogs;
    if (tab) {
      historyLogsFiltered = historyLogs.filter((hl) => {
        let tabName = parseTabNumberToJobHistoryLogCategory(tab);
        if (hl.category.includes(tabName)) {
          return true;
        }
      });
    }
    return historyLogsFiltered;
  };

  const fillJobLogsTable = async (info, tab = 0) => {
    const { history_logs } = info;
    if (history_logs) {
      history_logs.forEach((log) => {
        if (!log.category) {
          log.category = ["others"];
        }

        log.dateCreated = formatDate(log.date_created);
      });
      const historyLogsFiltered = filterHistoryLogs(history_logs, tab);
      setJobLogsTable({
        header: historyLogHeader,
        body: historyLogsFiltered,
      });
    }
  };
  useEffect(() => {
    const info = getJobById(jobId);
    if (info) {
      fillFilesTable(info);
      fillJobLogsTable(info);
      info.items = info.items_used.map((item) => {
        return {
          ...item,
          itemType: getItemTypeById(item.item_type_id),
        };
      });
      info.appointments.forEach((ap) => {
        ap.teamAssigned = getTeamById(ap.team_assigned_id);
      });
      setJobInfo(info);
    }
  }, [getJobById, getItemTypeById, getTeamById]);

  return {
    jobInfo,
    loadingJobs,
    addNote,
    addAppointment,
    filesTable,
    jobLogsTable,
    switchHistoryLogTab,
    editBillTo,
    editServiceLocation
  };
};

export default useViewJob;
