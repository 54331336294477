import EditIcon from 'icons/editIcon'
import ThrashIcon from 'icons/thrashIcon'
import React from 'react'

const ItemHeader = ({itemName, index, onDelete, onEdit, isEquipment}) => {
  return (
    <div style={{display: 'flex'}}>
      <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h1 style={{fontWeight: 'bold', fontSize: '18px'}}>{itemName}</h1>
          <span style={{fontSize: '12px', marginTop:'-4px', color:'#A4AAB3'}}>{isEquipment ? 'equipment' : 'price-book item'}</span>
        </div>
        <div style={{display: 'flex', alignItems: 'center', gap:'10px'}}>
          <EditIcon style={{cursor: 'pointer'}} onClick={()=>onEdit(index)}/>
          <ThrashIcon style={{cursor: 'pointer'}} onClick={()=>onDelete(index)}/>
        </div>
      </div>
    </div>
  )
}

export default ItemHeader