import { Signin } from "views/auth/signin";
import { ForgotPassword } from "views/auth/signin/forgotpassword";
import { ResetPassword } from "views/auth/signin/resetpassword";
import { ImpersonateSignin } from "views/auth/signin/impersonate";
import { Signup } from "views/auth/signup/account";
import { SignupUser } from "views/auth/signup/user";

const Routes = [
  {
    path: "/signup",
    view: Signup,
    layout: "auth",
    title: "Sign up to Gravity",
  },
  {
    path: "/signup/user",
    view: SignupUser,
    layout: "auth",
    title: "Sign up to Gravity",
  },
  {
    path: "/signin",
    view: Signin,
    layout: "auth",
    title: "Welcome to Trade Growth",
  },
  {
    path: "/forgotpassword",
    view: ForgotPassword,
    layout: "auth",
    title: "Forgot Your Password?",
  },
  {
    path: "/resetpassword",
    view: ResetPassword,
    layout: "auth",
    title: "Reset Your Password",
  },
  {
    path: "/signin/impersonate",
    view: ImpersonateSignin,
    layout: "auth",
    title: "Sign in via Mission Control",
  },
];

export default Routes;
