import { Label, Error, Icon, ClassHelper, TextInput } from 'components/lib';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import InputStyle from '../input.tailwind.js';
import Style from './date.module.scss';
import './custom.css';

export function DateInput(props){

  // init default value
  let date = props.value;
  if (date && date.indexOf('-') !== -1 && date.length >= 10){

    date = date.split('-');

    date = {

      year: parseInt(date[0]),
      month: parseInt(date[1]),
      day: parseInt(date[2])

    }
  } else if (date && date.indexOf('/') !== -1 && date.length >= 10) {

    date = date.split('/');

    date = {

      year: parseInt(date[2]),
      month: parseInt(date[0]),
      day: parseInt(date[1])

    }
  }
  else {

    const now = new Date();
    date = {

      year: now.getFullYear(),
      month: now.getMonth()+1,
      day: now.getDate()

    }
  }

  function formatDateString(d){
    let date;
    if (props.dateFormat === 'mm/dd/yyyy') {
      date = `${ d.month < 10 ? '0' + d.month : d.month }/${ d.day < 10 ? '0' + d.day : d.day }/${d.year}`;
    } else {
      date = `${d.year}-${ d.month < 10 ? '0' + d.month : d.month }-${ d.day < 10 ? '0' + d.day : d.day }`;
    }
    return date;
  }

  function change(date){

    let valid;

    if (props.required)
      valid = date.year && date.day && date.month ? true : false;

    props.onChange(props.name, formatDateString(date), valid);

  }

  const dateStyle = ClassHelper(InputStyle, {

    textbox: true, 
    error: props.valid === false,
    success: props.valid === true,

  });

  function customInput({ ref }){
    return (
      <input 
        ref={ ref } 
        value={ props.value?.split('T')[0] || '' }
        placeholder={ props.placeholder || 'Please select a date' }
        className={ dateStyle }
        onChange={()=>{}} //remove warning
      />
    );
  }
  
  return (
    <div className={ InputStyle.input + ' ' + props.containerClass}>

      { props.label && 
        <Label 
          text={ props.label } 
          required={ props.required }
          for={ props.name }
        /> }

        {props.disabled ? 
          <TextInput
            disabled
            value={ props.value }
          />
          :
          <DatePicker
            value={ date }
            onChange={ change }
            minimumDate={ props.min }
            maximumDate={ props.max }
            colorPrimary='#73B0F4'
            wrapperClassName={ Style.date }
            calendarClassName={ Style.gravityCalendarStyles }
            renderInput={ customInput }
            calendarPopperPosition='top'
          />
        }

        { props.valid === false &&
          <Error message={ props.errorMessage || 'Please select a date' }/> }

        { props.valid === true &&
          <Icon
            image='check'
            color='#8CC57D'
            className={ InputStyle.successIcon }
            size={ 16 }
          /> }

    </div>
  )
}