/* eslint-disable jsx-a11y/anchor-is-valid */
import { AuthContext, Button, Icon } from "components/lib";
import {
  approveEstimate,
  inviteEmployees,
  syncGoHighLevel,
  validateToken,
} from "lib/services";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

function base64ToBlob(base64String) {
  base64String = base64String.split(",")[1];
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new File([byteArray], "signature.png", { type: "image/png" });
}

export function ApproveEstimateLoading(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState(null);

  const [signaturePad, setSignaturePad] = useState(null);

  const context = useContext(AuthContext);

  const assignEstimate = () => {
    const signature = base64ToBlob(signaturePad.getTrimmedCanvas().toDataURL());
    setIsLoading(true);
    approveEstimate(token, signature)
      .then(() => {
        setTimeout(() => {
          setSuccess(true);
          setIsLoading(false);
        }, 3000);
      })
      .catch((error) => {
        setTimeout(() => {
          setError(error.response.data.message || "error");
          setSuccess(false);
          setIsLoading(false);
        }, 3000);
      });
  };

  const isTokenValid = (tokenPayload) => {
    setIsLoading(true);
    validateToken(tokenPayload)
      .then(() => {
        setTimeout(() => {
          setIsLoading(null);
        }, 300);
      })
      .catch((error) => {
        setTimeout(() => {
          setError(error.response.data.message || "error");
          setSuccess(false);
          setIsLoading(false);
        }, 300);
      });
  };

  useEffect(() => {
    setToken(queryParams.get("token"));
    if (!queryParams.get("token")) {
      return navigate("/estimates");
    }

    isTokenValid(queryParams.get("token"));
  }, []);

  return (
    <div className={"flex items-center justify-center flex-col loading-body" + (props.onboarding ? ' ' : ' h-screen')}>
      {!success && !isLoading && !error && (
        <>
          <div className={"p-6 w-4/5" + (props.onboarding ? ' ' : ' lg:w-2/5')}>
            <p className="text-xl text-center font-bold mb-4">
              Kindly provide your signature in the box below to sign your
              approval of the estimate:
            </p>
          </div>
        </>
      )}
      <div className={"w-4/5 bg-white p-8 rounded-lg text-center" + (props.onboarding ? ' ' : ' lg:w-2/5')}>
        {error ? (
          <>
            <p> Oops... Something went wrong!</p>
            <p>{error}</p>
            {context.user?.token && (
              <a href="/account" className="text-blue-500 hover:underline">
                Back to dashboard
              </a>
            )}
            <p>
              <a
                href="#"
                onClick={() => window.location.reload()}
                className="text-blue-500 hover:underline"
              >
                Try again
              </a>
            </p>
          </>
        ) : (
          <>
            {!isLoading && !success && (
              <>
                <div className="signature-line"></div>
                <SignatureCanvas
                  penColor="black"
                  ref={(ref) => {
                    setSignaturePad(ref);
                  }}
                  canvasProps={{
                    className: "sigCanvas signature-box",
                    height: "300",
                  }}
                />
                <div className="flex justify-center items-center">
                  <Button
                    text="APPROVE"
                    color="green"
                    className="px-16 approve-signature-button"
                    action={assignEstimate}
                  />
                </div>
                <div className="flex justify-center items-center">
                  <Button
                    text="CLEAR"
                    color="blue"
                    className="px-16 mt-4 clear-signature-button"
                    action={() => signaturePad.clear()}
                  />
                </div>

                <button
                  className="clear-signature"
                  onClick={() => signaturePad.clear()}
                >
                  <div className="p-2 w-9 h-9 rounded-full border border-gray-300">
                    <Icon image="rotate-ccw" size={18} />
                  </div>
                </button>
              </>
            )}
            {typeof isLoading === "boolean" && (
              <>
                <div className="flex items-center justify-center">
                  {isLoading ? (
                    <svg
                      aria-hidden="true"
                      className="w-32 h-32 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    <>
                      <svg
                        className="w-32 h-32 text-green-600 dark:text-green"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                    </>
                  )}
                </div>
                {isLoading ? (
                  <>
                    <h2 className="text-3xl font-bold mt-4">APPROVING...</h2>
                    <p className="text-gray-600 mt-2">
                      The estimate is being approved.
                    </p>
                  </>
                ) : (
                  <>
                    <>
                      <h2 className="text-3xl font-bold mt-4">THANK YOU!</h2>
                      <p className="text-gray-600 mt-2">
                        The estimate has been approved and signed.
                      </p>
                      {context.user?.token && (
                        <a
                          href="/account"
                          className="text-blue-500 hover:underline"
                        >
                          Back to dashboard
                        </a>
                      )}
                    </>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export function GoHighLevelSync(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const context = useContext(AuthContext);

  useEffect(() => {
    const code = queryParams.get("code");

    if (!code) {
      return navigate("/account");
    }

    syncGoHighLevel(code, context.user.account_id)
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      })
      .catch((error) => {
        setTimeout(() => {
          setError(error.response.data.message);
        }, 3000);
      });
  }, []);

  return (
    <div className={"flex items-center justify-center" + (props.onboarding ? ' ' : ' h-screen')}>
      <div className={"bg-white border border-gray-300 p-8 rounded-lg text-center" + (props.onboarding ? ' ' : ' w-2/5')}>
        {error ? (
          <>
            <p> Oops... Something went wrong!</p>
            <p>{error}</p>
            {context.user?.token && (
              <a href="/account" className="text-blue-500 hover:underline">
                Back to dashboard
              </a>
            )}
          </>
        ) : (
          <>
            <div className="flex items-center justify-center">
              {isLoading ? (
                <svg
                  aria-hidden="true"
                  className="w-32 h-32 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                <>
                  <svg
                    className="w-32 h-32 text-green-600 dark:text-green"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                </>
              )}
            </div>
            {isLoading ? (
              <>
                <h2 className="text-3xl font-bold mt-4">Syncing...</h2>
                <p className="text-gray-600 mt-2">
                  Please hang tight while we are syncing your Trade Growth
                  account to Job Estimate App.
                </p>
              </>
            ) : (
              <>
                <h2 className="text-3xl font-bold mt-4">THANK YOU!</h2>
                <p className="text-gray-600 mt-2">
                  Your Trade Growth account was successfully synced in the Job
                  Estimate App!
                </p>
                {context.user?.token && (
                  <a
                    href="/account"
                    className="text-blue-500 hover:underline"
                  >
                    Back to dashboard
                  </a>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export function InviteEmployees(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const context = useContext(AuthContext);

  useEffect(() => {
    inviteEmployees()
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      })
      .catch((error) => {
        setTimeout(() => {
          setError(error.response.data.message);
        }, 3000);
      });
  }, []);

  return (
    <div className={"flex items-center justify-center" + (props.onboarding ? ' ' : ' h-screen')}>
      <div className={"bg-white border border-gray-300 p-8 rounded-lg text-center" + (props.onboarding ? ' ' : ' w-2/5')}>
        {error ? (
          <>
            <p> Oops... Something went wrong!</p>
            <p>{error}</p>
            {context.user?.token && (
              <a href="/account" className="text-blue-500 hover:underline">
                Back to dashboard
              </a>
            )}
          </>
        ) : (
          <>
            <div className="flex items-center justify-center">
              {isLoading ? (
                <svg
                  aria-hidden="true"
                  className="w-32 h-32 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                <>
                  <svg
                    className="w-32 h-32 text-green-600 dark:text-green"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                </>
              )}
            </div>
            {isLoading ? (
              <>
                <h2 className="text-3xl font-bold mt-4">Inviting...</h2>
                <p className="text-gray-600 mt-2">
                  Please hang tight while we are inviting all employees to Job
                  Estimate App.
                </p>
              </>
            ) : (
              <>
                <h2 className="text-3xl font-bold mt-4">THANK YOU!</h2>
                <p className="text-gray-600 mt-2">
                  All employees on Trade Growth platform were invited to Job
                  Estimate App!
                </p>
                {context.user?.token && (
                  <a
                    href="/account"
                    className="text-blue-500 hover:underline"
                  >
                    Back to dashboard
                  </a>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
