import { FlexContainer, Icon } from 'components/lib';
import React from 'react';

const AppointmentDetail = ({ title, description, icon }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <Icon className='mt-1 mr-2' image={icon} />
        <strong>
          {title}
        </strong>
      </div>
      <div className='ml-6'>
        {description}
      </div>
    </div>
  )
}

const Appointment = ({ appointment }) => {
  return (
    <div
      style={{
        padding: '1em',
        border: '1px solid lightgrey',
        borderRadius: '1em'
      }}
    >
      <h1 className='text-lg font-semibold mb-4'>{appointment.name}</h1>
      <FlexContainer columnGap='3rem'>
        <AppointmentDetail title='Start Date' description={appointment.start_date} icon='calendar' />
        <AppointmentDetail title='Start Time' description={appointment.start_time} icon='clock' />
        <AppointmentDetail title='End Date' description={appointment.end_date} icon='calendar' />
        <AppointmentDetail title='End Time' description={appointment.end_time} icon='clock' />
        <AppointmentDetail title='Team' description={appointment.teamAssigned?.name} icon='users' />
      </FlexContainer>
    </div>
  )
}

export default Appointment