import { ViewContext } from "components/lib";
import { MainContext } from "contexts/MainContext";
import {
  archiveJob,
  unarchiveJob,
  getAllJobs,
  completeJob,
  backJob,
} from "lib/services";
import { useState, useEffect, useContext, useCallback } from "react";
import useCustomers from "./useCustomers";
import { useFilesViewer } from "./useFilesViewer";
import { moneyFormatter } from "lib";
import { formatDate } from "helpers/dateFormatter";
import {
  dateGreaterOrEqualThan,
  dateLessThan,
  parseYearMonthDayToISODateString,
} from "./lib";

const header = [
  { name: "id", title: "ID", sort: true },
  { name: "customerName", title: "Customer Name", sort: true },
  { name: "service_location", title: "Service Location", sort: true },
  { name: "revenue", title: "Revenue", sort: true },
  { name: "completion_date", title: "Completion Date", sort: true },
  { name: "status", title: "Status", sort: true },
  { name: "approved_date", title: "Date Approved", sort: true },
];

const subMenus = ["Active", "Archived", "Completed"];

const useJobs = (mainPage) => {
  const viewContext = useContext(ViewContext);
  const {
    allJobsData,
    setAllJobsData,
    allJobsInProgressData,
    setAllJobsInProgressData,
    loadingJobs,
    setLoadingJobs,
  } = useContext(MainContext);
  const { onViewFiles, loadingFiles } = useFilesViewer();

  const [jobsWithNoSchedules, setJobsWithNoSchedules] = useState();
  const [jobsInProgress, setJobsInProgress] = useState();
  const [jobsData, setJobsData] = useState([]);
  const [showJobsType, setJobsType] = useState(0);
  const [jobOptions, setJobOptions] = useState();
  const defaultDateValue = {
    from: null,
    to: null,
  };
  const [selectedDayRange, setSelectedDayRange] = useState(defaultDateValue);
  const [dateFilterParams, setDateFilterParams] = useState({});

  useEffect(()=>{
    const { from, to } = selectedDayRange;
    if(from && to) {
      const filterParams = {
        start_date: `${from.year}-${from.month}-${from.day}`,
        end_date: `${to.year}-${to.month}-${to.day}`,
      }
      setDateFilterParams(filterParams)
    }
  }, [selectedDayRange])

  const selectJobsToShow = (type) => {
    setJobsType(type);
  };

  const getJobById = useCallback((jobId) => {
    return allJobsData?.find((job) => job.id === jobId);
  },[allJobsData]);

  const handleJobArchiveAction = async (job) => {
    handleArchiveAction(job, showJobsType, reload, setLoadingJobs);
  };

  const handleJobCompleteAction = async (job) => {
    handleCompleteAction(job, reload, setLoadingJobs);
  };

  const handleBackAction = async (job) => {
    handleBackJobAction(job, reload, setLoadingJobs);
  };

  const handleCompleteAction = async (job, reload, setLoading) => {
    viewContext.modal.show(
      {
        title: `Do you want to complete the job ${job.customerName} (${job.job_code})?`,
        form: {},
        buttonText: "Complete",
        method: "PATCH",
        url: "/api/complete-jobs/" + job.id,
      },
      async () => {
        viewContext.modal.hide();
        reload();
      }
    );
  };

  const handleBackJobAction = async (job, reload, setLoading) => {
    viewContext.modal.show(
      {
        title: `Do you want to move the job ${job.customerName} (${job.job_code}) back to the active tab?`,
        form: {},
        buttonText: "Back",
        method: "PATCH",
        url: "/api/back-jobs/" + job.id,
      },
      async () => {
        viewContext.modal.hide();
        reload();
      }
    );
  };

  const handleArchiveAction = async (job, showJobsType, reload, setLoading) => {
    const { id } = job;

    if (typeof showJobsType === "boolean") {
      showJobsType = showJobsType ? 0 : 1;
    }

    if (subMenus[showJobsType] !== "Archived") {
      viewContext.modal.show(
        {
          title: `Do you want to archive ${
            job.customerName || job.customer_id
          }?`,
          form: {},
          buttonText: "Archive",
          method: "PATCH",
          url: "/api/archive-jobs/" + id,
        },
        async () => {
          viewContext.modal.hide();
          reload();
        }
      );
    } else {
      viewContext.modal.show(
        {
          title: `Do you want to unarchive ${
            job.customerName || job.customer_id
          }?`,
          form: {},
          buttonText: "Unarchive",
          method: "PATCH",
          url: "/api/unarchive-jobs/" + id,
        },
        async () => {
          viewContext.modal.hide();
          reload();
        }
      );
    }
  };

  const handleAddMedia = (job) => {
    onViewFiles(job);
  };

  const handleAddInformationToJob = (job, reloadJob) => {
    viewContext.modal.show(
      {
        title: `Add information`,
        form: {
          job_id: { value: job.id, type: "hidden" },
          note_description: {
            type: "textarea",
            label: "Notes about the Job",
            required: true,
          },
          files: {
            type: "file",
          },
        },
        buttonText: "Save",
        method: "POST",
        url: "/api/create-job-notes",
      },
      async () => {
        reloadJob();
        reload();
        viewContext.modal.hide();
      }
    );
  };

  const handleEditServiceLocation = (job, reloadJob) => {
    viewContext.modal.show(
      {
        title: `Update the service location`,
        form: {
          job_id: { value: job.id, type: "hidden" },
          name: {
            value: job?.service?.name,
            type: "text",
            required: false,
            label: "Name",
            containerClass: "w-full",
          },
          address: {
            value: job?.service?.address,
            type: "text",
            required: false,
            label: "Address",
            containerClass: "w-full",
          },
          phone: {
            value: job?.service?.phone,
            type: "phone",
            required: false,
            label: "Phone",
            containerClass: "w-full",
          },
          email: {
            value: job?.service?.email,
            type: "email",
            required: false,
            label: "Email",
            containerClass: "w-full",
          },
        },
        buttonText: "Update",
        method: "PUT",
        url: `/api/update-service-location/${job.id}`,
      },
      async () => {
        reloadJob();
        reload();
        viewContext.modal.hide();
      }
    );
  };

  const handleEditBillTo = (job, reloadJob) => {
    viewContext.modal.show(
      {
        title: `Update bill to`,
        form: {
          name: {
            value: job?.bill_to?.name,
            type: "text",
            required: false,
            label: "Name",
            containerClass: "w-full",
          },
          address: {
            value: job?.bill_to?.address,
            type: "text",
            required: false,
            label: "Address",
            containerClass: "w-full",
          },
          phone: {
            value: job?.bill_to?.phone,
            type: "phone",
            required: false,
            label: "Phone",
            containerClass: "w-full",
          },
          email: {
            value: job?.bill_to?.email,
            type: "email",
            required: false,
            label: "Email",
            containerClass: "w-full",
          },
        },
        buttonText: "Update",
        method: "PUT",
        url: `/api/update-bill-to/${job.id}`,
      },
      async () => {
        reloadJob();
        reload();
        viewContext.modal.hide();
      }
    );
  };

  const loadJobOptions = useCallback((jobsRawData)=>{
    const options = jobsRawData.map(job => ({
      label: (`${job.customer?.name ?? job.customerName} - ${job.job_code}`),
      value: job.id,
    }))
    setJobOptions(options);
  }, [setJobOptions])

  const loadJobs = useCallback(
    (jobs) => {
      loadJobOptions(jobs);
      let dateFilteredJobs = jobs;
      const { from, to } = selectedDayRange;
      if (from) {
        const fromParsed = parseYearMonthDayToISODateString(from);
        dateFilteredJobs = dateFilteredJobs.filter((job) =>
          dateGreaterOrEqualThan(job.date_created, fromParsed)
        );
      }
      if (to) {
        const toParsed = parseYearMonthDayToISODateString(to);
        dateFilteredJobs = dateFilteredJobs.filter((job) =>
          dateLessThan(job.date_created, toParsed)
        );
      }

      const parsedJobs = dateFilteredJobs.map((job) => ({
        customerName: job.customer.name,
        service_location: job.service.address,
        revenue: moneyFormatter(job.revenue),
        completion_date: formatDate(job.completion_date),
        status: job.status,
        ...job,
        revenue: moneyFormatter(job.revenue),
        approved_date: formatDate(job.approved_date),
      }));

      const jobsFiltered = parsedJobs.filter((job) => {
        if (subMenus[showJobsType] === "Active") {
          return job.active && job.status !== "Completed";
        } else if (subMenus[showJobsType] === "Archived") {
          return !job.active;
        } else if (subMenus[showJobsType] === "Completed") {
          return job.status === "Completed";
        } else {
          return true;
        }
      });

      setJobsData({ body: jobsFiltered, header });

      const commonHeaders = [
        { name: "id", title: "ID" },
        { name: "job_code", title: "Job Code", sort: true },
        { name: "customerName", title: "Customer Name", sort: true },
        { name: "completion_date", title: "Completion Date", sort: true },
      ];

      const jobsInProgress = parsedJobs.filter(
        (job) => job.status === "In progress" && job.active
      );
      setJobsWithNoSchedules({
        body: parsedJobs
          .filter((job) => !job.appointments.length)
          .map(job => ({
            id: job.id,
            job_code: job.job_code,
            customerName: job.customerName,
          })),
        header: commonHeaders,
      });
      setJobsInProgress({
        body: jobsInProgress.map((job) => ({
          id: job.id,
          job_code: job.job_code,
          customerName: job.customerName,
          approved_date: job.approved_date,
        })),
        header: [
          ...commonHeaders,
          { name: "approved_date", title: "Approval date", sort: true },
        ],
      });
      setAllJobsInProgressData(jobsInProgress);
    },
    [selectedDayRange, setAllJobsInProgressData, showJobsType]
  );

  const reload = useCallback(async () => {
    setLoadingJobs(true);
    const { data } = await getAllJobs();
    const jobs = data.map((job) => ({
      ...job,
      customerName: job.customer?.name,
      completion_date: job.completion_date
        ? job.completion_date.split("T")[0]
        : null,
    }));
    setAllJobsData(jobs);

    const jobsInProgress = data.filter(
      (job) => job.status === "In progress" && job.active
    );
    setAllJobsInProgressData(jobsInProgress);
    setLoadingJobs(false);
    loadJobs(jobs);
  }, [
    loadJobOptions,
    setLoadingJobs,
    loadJobs,
    setAllJobsData,
    setAllJobsInProgressData,
  ]);

  useEffect(() => {
    if (mainPage) {
      reload();
    }
  }, []);

  useEffect(() => {
    if (!loadingJobs && !allJobsData) {
      reload();
    } else if (allJobsData) {
      loadJobs(allJobsData);
    }
  }, [reload, loadJobs, allJobsData, loadingJobs]);

  return {
    reload,
    loadingJobs,
    selectJobsToShow,
    showJobsType,
    handleArchiveAction,
    handleJobArchiveAction,
    handleAddMedia,
    handleAddInformationToJob,
    handleJobCompleteAction,
    handleBackAction,
    jobsData,
    getJobById,
    allJobsData,
    allJobsInProgressData,
    loadingFiles,
    handleEditServiceLocation,
    handleEditBillTo,
    jobsInProgress,
    jobsWithNoSchedules,
    filterDate: setSelectedDayRange,
    selectedDayRange,
    jobOptions,
    dateFilterParams,
  };
};

export default useJobs;
