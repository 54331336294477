const Style = {

  base: 'relative p-6 x-1 mb-6 bg-white rounded last-of-type:mb-0',
  header: 'relative pb-5 mb-5 border-dotted border-slate-100 border-b flex justify-between',
  title: 'float:left m-0 text-lg font-semibold',
  center: 'mx-auto',
  loading: 'h-60',
  shadow: 'drop-shadow-sm',
  noPadding: '!p-0',
  restrictWidth: 'max-w-md',
  last: 'mb-0',
  fullWidth: 'w-full',
  outline: 'outline outline-gray-200 outline-1'

}

export default Style