/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import { Animate, AuthContext, ViewContext, Button, Form, Card, Link, Row, Message, SocialSignin, useLocation, useNavigate } from 'components/lib';

export function Signin(props){

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();
  
  const [form, setForm] = useState({

    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot your password?'
    }
  });

  useEffect(() => {

    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes('error')){
  
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=')+7));
      viewContext.notification.show(msg, 'error');
  
    }
  }, [location.search, viewContext.notification]);

  return(
    <Animate type='pop'>
      <Row title='Sign in to Jobs by Trade Growth'>

        <Card restrictWidth center>

          <Form
            data={ form }
            method='POST'
            url={ '/api/auth' }
            buttonText={ 'Sign In' }
            callback={ res => {

              res.data['2fa_required'] ? navigate(`/signin/otp?token=${res.data.token}`) : navigate(authContext.signin(res));

            }}
          />

          <div className='mt-4'>
            Don't have an account? <Link url='/signup' text='Sign Up'/>
          </div>

        </Card>
      </Row>
    </Animate>
  );
}
