/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Onboarding, Form, Message, Card, Icon, Link } from 'components/lib';
import { InviteEmployees } from 'views/loading/loading';
import Company from 'views/account/company';
import Teams from 'views/dashboard/teams';
import settings from "../../settings.json";

export function OnboardingView(props){

  const context = useContext(AuthContext);
  const views = [
    {
      name: 'Getting Started',
      description: `Welcome, ${context.user.name}!`,
      component: <Welcome/>
    },
    {
      name: 'Company',
      description: `Company Information`,
      component: <Company onboarding/>
    },
    {
      name: 'Invite Employees',
      description: `Inviting Employees`,
      component: <InviteEmployees onboarding/>
    },
    {
      name: 'Teams',
      description: `Your Company Teams`,
      component: <Teams/>
    },
  ]

  if (context.permission.admin){
    views.splice(2, 0, {

      name: 'Invite your team', 
      description: 'Gravity is more fun when you invite your team.',
      component: <InviteUsers/>,
      
    });
  }

  if (context.permission.admin){
    views.splice(1, 0, {

      name: 'Trade Growth Sync', 
      description: 'Sync your account with Trade Growth',
      component: <TradeGrowthSync/>
      
    });
  }
  if (context.user.duplicate_user){
    views.unshift({

      name: 'Important!',
      description: '',
      component: <DuplicateUser/>

    })
  }

  return <Onboarding save onFinish='/dashboard' views={ views }/>

}

const TradeGrowthSync = () => (
  <Card>
    <Icon image="refresh-cw" size={20} />
    <h2 className={"font-bold mt-4"}>Trade Growth Sync</h2>
    <div>Sync your account with Trade Growth</div>
    <Link
      text="Sync"
      url={settings.production.GoHighLevelSync}
      className={"text-blue-500 font-semibold mt-3 inline-block"}
    >
      Sync
    </Link>
  </Card>
)

function DuplicateUser(){

  return (
    <Message
      type='warning'
      title={ `You already have an account` }
      text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
    />    
  )
}

function Welcome(){

  return (
    <Fragment>

      <p>This is the onboarding for Trade Growth, to continue, you need to configure some information about your company.</p>

    </Fragment>
  )
}

function InviteUsers(props){

  return (
    <Form 
      data={{
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        }
      }}
      buttonText='Send Invite'
      url='/api/invite'
      method='POST'
    />
  )
}