import { Card, Table } from 'components/lib';
import { moneyFormatter } from 'lib';
import React, { useEffect, useState } from 'react'


const header = [
  { name: 'itemName', title: 'Name', sort: true },
  { name: 'itemCategory', title: 'Category Item', sort: true },
  { name: 'itemType', title: 'Type', sort: true },
  { name: 'itemQuantity', title: 'Quantity', sort: true },
  { name: 'costPer', title: 'Price Per:', sort: true },
  { name: 'itemPrice', title: 'Price', sort: true },
  { name: 'totalPrice', title: 'Total Price', sort: true },
]
const equipmentHeader = [
  { name: 'name', title: 'Name', sort: true },
  { name: 'daily_rate', title: 'Daily Rate', sort: true },
  { name: 'hourly_rate', title: 'Hourly Rate', sort: true },
  { name: 'description', title: 'Description', sort: true },
]

const Items = ({ items, equipment }) => {
  const [itemsTable, setItemsTable] = useState([]);
  const [equipmentsTable, setEquipmentsTable] = useState({});
  const [totalItemsPrice, setTotalItemsPrice] = useState("0");
  useEffect(() => {
    let totalItemsPriceVar = 0
    if (items) {
      const body = items.map(item => {
        const totalPrice = (item.item_price && item.quantity_used) ? item.item_price * item.quantity_used : 0;
        totalItemsPriceVar = (Number(totalItemsPriceVar) + Number(totalPrice));
        setTotalItemsPrice(moneyFormatter(totalItemsPriceVar));
        const category = item.inventory_item_id ? 'Price-book' : 'One-off';
        return {
          itemName: item.item_name,
          itemCategory: category,
          itemType: item.itemType?.label,
          itemQuantity: item.quantity_used,
          costPer: item.cost_per,
          itemPrice: moneyFormatter(item.item_price),
          totalPrice: moneyFormatter(totalPrice),
        }
      })
      setItemsTable({ header, body });
    }
    if(equipment) {
      const body = equipment.map(eq => ({
        ...eq,
        daily_rate: moneyFormatter(eq.daily_rate),
        hourly_rate: moneyFormatter(eq.hourly_rate),
      }))
      setEquipmentsTable({ header: equipmentHeader, body })
    }
  }, [items])
  return (
    <>
      <Card title='Job Items'>
        <Table
          data={itemsTable}
          show={[
            'itemName',
            'itemCategory',
            'itemType',
            'itemQuantity',
            'costPer',
            'itemPrice',
            'totalPrice',
          ]}
        />
        <div className='total-items-price'><strong>Total Items Price</strong>: {totalItemsPrice}</div>
      </Card>
      <Card title='Equipment Used'>
        <Table
          data={equipmentsTable}
          show={[
            'name',
            'daily_rate',
            'hourly_rate',
            'description',
          ]}
        />
      </Card>
    </>
  )
}

export default Items