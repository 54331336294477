import React, { createContext, useState } from "react";

export const MainContext = createContext({
  inventoryItems: null,
  setInventoryItems: () => {},
  equipments: null,
  setEquipments: () => {},
  itemsCollection: null,
  setItemsCollection: () => {},
  itemTypes: null,
  setItemTypes: () => {},
  estimatesData: null,
  setEstimatesData: () => {},
  estimatesRawData: null,
  setEstimatesRawData: () => {},
  loadingItems: null,
  setLoadingItems: () => {},
  loadingEstimates: null,
  setLoadingEstimates: () => {},
  allJobsData: null,
  setAllJobsData: () => {},
  loadingJobs: null,
  setLoadingJobs: () => {},
  teamsData: null,
  setTeamsData: () => {},
  appointmentsData: null,
  setAppointmentsData: () => {},
  customersData: null,
  setCustomersData: () => {},
  loadingCustomers: null,
  setLoadingCustomers: () => {},
  employeesData: null,
  setEmployeesData: () => {},
  allJobsInProgressData: null,
  setAllJobsInProgressData: () => {},
  timesheet: null,
  setTimesheet: () => {},
  profitabilityData: null,
  setProfitabilityData: () => {},
});

const MainProvider = ({ children }) => {
  const [estimatesData, setEstimatesData] = useState({});
  const [estimatesRawData, setEstimatesRawData] = useState(null);
  const [inventoryItems, setInventoryItems] = useState(null);
  const [equipments, setEquipments] = useState(null);
  const [itemsCollection, setItemsCollection] = useState(null);
  const [itemTypes, setItemTypes] = useState(null);
  const [loadingItems, setLoadingItems] = useState();
  const [loadingEstimates, setLoadingEstimates] = useState();
  const [allJobsData, setAllJobsData] = useState(null);
  const [loadingJobs, setLoadingJobs] = useState();
  const [teamsData, setTeamsData] = useState(null);
  const [loadingTeams, setLoadingTeams] = useState();
  const [appointmentsData, setAppointmentsData] = useState(null);
  const [customersData, setCustomersData] = useState(null);
  const [loadingCustomers, setLoadingCustomers] = useState();
  const [employeesData, setEmployeesData] = useState(null);
  const [allJobsInProgressData, setAllJobsInProgressData] = useState(null);
  const [timesheet, setTimesheet] = useState(null);
  const [profitabilityData, setProfitabilityData] = useState({});

  return (
    <MainContext.Provider
      value={{
        inventoryItems,
        setInventoryItems,
        equipments,
        setEquipments,
        itemsCollection,
        setItemsCollection,
        itemTypes,
        setItemTypes,
        estimatesData,
        setEstimatesData,
        estimatesRawData,
        setEstimatesRawData,
        loadingItems,
        setLoadingItems,
        loadingEstimates,
        setLoadingEstimates,
        allJobsData,
        setAllJobsData,
        loadingJobs,
        setLoadingJobs,
        teamsData,
        setTeamsData,
        loadingTeams,
        setLoadingTeams,
        appointmentsData,
        setAppointmentsData,
        customersData,
        setCustomersData,
        loadingCustomers,
        setLoadingCustomers,
        employeesData,
        setEmployeesData,
        allJobsInProgressData, 
        setAllJobsInProgressData,
        timesheet,
        setTimesheet,
        profitabilityData,
        setProfitabilityData,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default MainProvider;
