import { FlexContainer } from "components/lib";
import React, { useEffect, useState } from "react";

const JobNote = ({ note }) => {
  const [dateCreated, setDateCreated] = useState("");

  useEffect(() => {
    if (note) {
      const dateAndTime = note.date_created.split("T");
      const dateArray = dateAndTime[0].split("-");
      const dateString = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
      const timeArray = dateAndTime[1].split(":");
      const timeString = `${timeArray[0]}:${timeArray[1]}`;
      const dateTimeString = `${dateString} ${timeString}`;
      setDateCreated(dateTimeString);
    }
  }, [note]);
  return (
    <div
      style={{
        border: "1px solid lightgrey",
        borderRadius: "0.4em",
        display: "flex",
        padding: "0.5em",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <strong>{note.username}</strong>
        <div>{note.note_description}</div>
        <div>{dateCreated}</div>
      </div>
    </div>
  );
};

export default JobNote;
