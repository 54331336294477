import React from 'react'

const ItemBodyItem = ({title, content, width}) => {
  return (
    <div style={{display: 'flex', flexDirection:'column', width: width, padding:'5px'}}>
      <span style={{fontSize: '12px', color: '#A4AAB3'}}>{title}</span>
      <span style={{fontSize: 'px',}}>{content}</span>
    </div>
  )
}

export default ItemBodyItem