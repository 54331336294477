const Style = {

  base: 'inline-block mx-1 py-1 px-2.5 uppercase text-xs rounded',
  clickable: 'cursor-pointer',
  textWhite: 'text-white',
  textBlack: 'text-black',
  red: 'bg-red-500',
  blue: 'bg-blue-500',
  green: 'bg-emerald-500',
  orange: 'bg-orange-500',
  yellow: 'bg-yellow-500',
  grey: 'bg-grey',
  fitContent: 'height-fit-content width-fit-content',
  
}

export default Style;