import { useNavigate, ViewContext } from "components/lib";
import { MainContext } from "contexts/MainContext";
import {
  getAllEstimates,
  sendEstimateOffer,
  turnEstimateToJob,
} from "lib/services";
import { useState, useEffect, useContext, useCallback } from "react";
import useCustomers from "./useCustomers";
import useJobs from "./useJobs";
import { moneyFormatter } from "lib";

const header = [
  { name: "id", title: "ID", sort: true },
  { name: "customerName", title: "Customer Name", sort: true },
  { name: "email", title: "E-mail", sort: true },
  { name: "phone", title: "Phone", sort: true },
  { name: "dateCreated", title: "Date Created", sort: true },
  { name: "days_to_complete", title: "Days To Complete", sort: true },
  { name: "estimatedValue", title: "Estimate Value", sort: true },
  { name: "status", title: "Status", sort: true },
];

const useEstimates = (mainPage) => {
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();

  const { getCustomerNameById } = useCustomers();

  const { handleArchiveAction, reload: reloadJobs } = useJobs();

  const {
    estimatesData,
    setEstimatesData,
    estimatesRawData,
    setEstimatesRawData,
    loadingEstimates,
    setLoadingEstimates,
  } = useContext(MainContext);

  const [showActiveEstimates, setShowActiveEstimates] = useState(true);

  const handleEstimateArchiveAction = (job) => {
    handleArchiveAction(job, showActiveEstimates, reload, setLoadingEstimates);
  };

  const handleEditEstimate = ({ id: estimateId }) => {
    navigate("/estimates/edit/" + estimateId);
  };

  const goToCreateNewEstimate = () => {
    navigate("/estimates/new");
  };
  const goToEstimates = () => {
    navigate("/estimates");
  };

  const toggleEstimatesToShow = () => {
    setShowActiveEstimates(!showActiveEstimates);
  };

  const approveEstimate = async (job) => {
    const { id } = job;
    viewContext.modal.show(
      {
        title: `Do you want to approve ${job.customerName || job.customer_id}?`,
        form: {},
        buttonText: "Approve",
      },
      async () => {
        try {
          viewContext.modal.hide();
          setLoadingEstimates(true);
          await turnEstimateToJob(id);
          viewContext.notification.show("Approved!", "success", true);
        } catch (error) {
          viewContext.notification.show(
            "There's been an error. Check if the approval mail has already been sent to the customer.",
            "error",
            false
          );
        } finally {
          setLoadingEstimates(false);
          reloadJobs();
          reload();
        }
      }
    );
    return;
  };

  const sendQuote = async (job) => {
    const { id } = job;
    viewContext.modal.show(
      {
        title: `Do you want to send the estimate to ${
          job.customerName || job.customer_id
        }?`,
        form: {},
        buttonText: "Send",
      },
      async () => {
        try {
          viewContext.modal.hide();
          setLoadingEstimates(true);
          await sendEstimateOffer(id);
          viewContext.notification.show(
            "Quote sent to customer!",
            "success",
            true
          );
        } catch (error) {
          viewContext.notification.show(
            "There's been an error.",
            "error",
            false
          );
        } finally {
          setLoadingEstimates(false);
          reload();
        }
      }
    );
    return;
  };

  const convertStatus = (status) => {
    return status === "Sent to customer" ? "SENT" : status.toUpperCase();
  };

  const reload = useCallback(() => {
    setLoadingEstimates(true);
    getAllEstimates().then((data) => {
      setLoadingEstimates(false);
      setEstimatesRawData(data.data);
      const body = data.data
        .filter((job) => showActiveEstimates === job.active)
        .map((job) => {
          const createdDate = job.date_created.split("T")[0];
          const { name, phone, email } = job.customer;
          return {
            id: job.id,
            customerName: name,
            email: email || "Loremipsum@Loremipsum",
            phone: phone || "X XXX-XXXX",
            dateCreated: createdDate,
            days_to_complete: job.days_to_complete || 0,
            estimatedValue: moneyFormatter(job.estimate_value),
            status: convertStatus(job.status),
          };
        });
      const jobs = {
        header,
        body,
      };
      setEstimatesData(jobs);
    });
  }, [
    showActiveEstimates,
    setLoadingEstimates,
    setEstimatesData,
    getCustomerNameById,
  ]);

  useEffect(() => {
    if (mainPage || !estimatesData.body) {
      reload();
    }
  }, [showActiveEstimates]);

  return {
    estimatesData,
    estimatesRawData,
    reload,
    loadingEstimates,
    toggleEstimatesToShow,
    showActiveEstimates,
    handleEstimateArchiveAction,
    goToCreateNewEstimate,
    goToEstimates,
    approveEstimate,
    sendQuote,
    handleEditEstimate,
  };
};

export default useEstimates;
