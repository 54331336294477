import { MainContext } from "contexts/MainContext";
import { getEmployees } from "lib/services";
import { useEffect, useContext, useCallback } from "react";

const useEmployees = () => {
  const { employeesData, setEmployeesData } = useContext(MainContext);

  const getEmployeesByPartialName = (text, values) => {
    return employeesData.filter((employee) => {
      const firstName = employee.first_name.toLowerCase();
      const lastName = employee.last_name.toLowerCase();
      const fullName = `${firstName} ${lastName}`;
      const fullNameBackwards = `${lastName} ${firstName}`;
      const textToLower = text.toLowerCase().trim();

      return (
        !values.some((value) => value.id === employee.id) &&
        (firstName.includes(textToLower) ||
          lastName.includes(textToLower) ||
          fullName.includes(textToLower) ||
          fullNameBackwards.includes(textToLower))
      );
    });
  };

  const getEmployeeById = (id) => {
    return employeesData.find((employee) => employee.id === id);
  };

  const getEmployeesByIds = (ids) => {
    return ids.map((id) => getEmployeeById(id));
  };

  const reload = useCallback(async () => {
    const data = await getEmployees();
    if (data) {
      const employees = data.map((employee) => ({
        ...employee,
        fullName: `${employee.first_name} ${employee.last_name}`,
      }));
      setEmployeesData(employees);
    }
  }, [setEmployeesData]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    employeesData,
    getEmployeesByPartialName,
    getEmployeeById,
    getEmployeesByIds,
  };
};

export default useEmployees;
