import React from 'react'

const GeneralCosts = (props) => {
  return (
    <div className='w-full flex gap-8 mb-4'>
      <div>
        <div>Items' Total Cost</div>
        <div className='font-bold text-lg'>U${props.value?.itemsTotalCost ?? '5.00'}</div>
      </div>
      <div>
        <div>Overhead Cost</div>
        <div className='font-bold text-lg'>U${props.value?.overheadCost ?? '5.00'}</div>
      </div>
      <div>
        <div>Breakeven cost</div>
        <div className='font-bold text-lg'>U${props.value?.breakevenCost ?? '5.00'}</div>
      </div>
    </div>
  )
}

export default GeneralCosts