import { ShowCostPriceAndEditPercentage, ViewContext, useAPI } from "components/lib";
import { createJob, updateJob } from "lib/services";
import { useCallback, useContext, useEffect, useState } from "react";
import { costPerOptions, createJobForm, itemBaseForm } from "./lib";
import useItems from "./useItems";
import useEstimates from "./useEstimates";
import useCustomers from "./useCustomers";
import { getPriceByCostAndProfitPercentage } from "lib";

const initialEmptyFormData = {
  customer: "",
  estimateValue: "",
  valueWithDiscount: "",
  generalCosts: {
    itemsTotalCost: 0,
    overheadCost: 0,
    breakevenCost: 0,
  },
  applyDiscount: null,
  days: null,
  extraNotes: "",
};

const useCreateJob = (estimateId) => {
  const {
    itemTypes,
    itemsCollection,
    equipments,
    itemsOptions,
  } = useItems();

  const { data } = useAPI('/api/employees/salary');

  const { goToEstimates, estimatesRawData } = useEstimates();

  const { loadingCustomers, customersOptions } = useCustomers();

  const viewContext = useContext(ViewContext);

  const [estimateToEdit, setEstimateToEdit] = useState();

  const [oneOffItems, setOneOffItems] = useState([]);
  const [equipmentForEstimate, setEquipmentForEstimate] = useState([]);
  const [formData, setFormData] = useState(initialEmptyFormData);
  const [form, setForm] = useState(createJobForm(formData, customersOptions));
  const [saving, setSaving] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const readyToEdit = !!(
    estimateId &&
    estimateToEdit &&
    customersOptions.length &&
    itemTypes
  );
  const loadingEditScreen = estimateId && !readyToEdit;

  useEffect(() => {
    if (estimatesRawData) {
      const estimate = estimatesRawData.find((est) => est.id === estimateId);
      setEstimateToEdit(estimate);
    }
  }, [estimatesRawData]);

  useEffect(() => {
    if (estimateToEdit) {
      setFormData({
        customer: estimateToEdit.customer_id,
        extraNotes: estimateToEdit.extra_notes,
        applyDiscount: estimateToEdit.discount,
        discount_expiration_date: estimateToEdit.discount_expiration?.date,
        days: estimateToEdit.days_to_complete,
        files_to_keep: estimateToEdit.medias,
        estimateValue: estimateToEdit.estimate_value,
      });
    }
  }, [estimateToEdit, saving]);

  useEffect(() => {
    if (readyToEdit) {
      const allItems = estimateToEdit.items_used.map((item) => ({
        inventoryItemId: item.inventory_item_id,
        itemType: item.item_type_id,
        itemName: item.item_name,
        itemQuantity: item.quantity_used,
        itemPrice: item.item_price,
        itemCost: item.item_cost,
        totalPrice: (item.quantity_used * item.item_price).toFixed(2),
        profit_percentage: item.profit_percentage,
        itemType: itemTypes.find((type) => type.value === item.item_type_id),
        costPer: costPerOptions.find(
          (option) => option.value === item.cost_per
        ),
      }));
      const allEquipment = estimateToEdit.equipment_used.map((eq) => {
        const timeType = eq.hours_needed ? 'hours_needed' : 'days_needed';
        const rate = timeType === 'hours_needed' ? eq.hourly_rate : eq.daily_rate;
        const equipment = {
          ...eq,
          timeNeeded: eq.hours_needed ?? eq.days_needed,
          timeType,
        }
        equipment.totalCost = parseFloat(equipment.timeNeeded) * parseFloat(rate);
        return equipment;
      });
      setEquipmentForEstimate(allEquipment);
      setOneOffItems(allItems);
    }
  }, [readyToEdit]);

  const updateEstimateValue = useCallback((estimateValue, currentForm) => {
    const newForm = {
      ...currentForm,
      estimateValue: {
        ...currentForm.estimateValue,
        value: estimateValue,
      },
    }
    setForm(newForm);
    return newForm;
  }, [setForm]);

  const updateGeneralCosts = (costsSum, overheadCost, breakevenCost, currentForm)=>{
    const newForm = {
      ...currentForm,
      generalCosts: {
        ...currentForm.generalCosts,
        value: {
          itemsTotalCost: costsSum,
          overheadCost,
          breakevenCost,
        }
      }
    }
    return newForm;
  }

  useEffect(()=>{
    if (
        customersOptions.length &&
        data &&
        (!estimateId ||
          (
            readyToEdit &&
            formData.days &&
            formData.applyDiscount
    ))) {
      let currentForm = createJobForm(formData, customersOptions);
      const { daily_salary } = data;
      const itemsCostsSum = oneOffItems.reduce(
        (prev, cur) => prev + parseFloat(cur.itemQuantity * cur.itemCost),
        0
      );
      const equipmentCostsSum = equipmentForEstimate.reduce(
        (prev, cur) => prev + parseFloat(cur.totalCost),
        0
      );
      const itemsPricesSum = oneOffItems.reduce(
        (prev, cur) => prev + parseFloat(cur.totalPrice),
        0
      );
      const costsSum = itemsCostsSum + equipmentCostsSum;
      const overheadCost = formData.days * daily_salary;
      const breakevenCost = overheadCost + costsSum;
      const estimateValueCalculated = overheadCost + itemsPricesSum + equipmentCostsSum;
      currentForm = updateGeneralCosts(costsSum, overheadCost, breakevenCost, currentForm);
      const estimateValueToSet = (estimateId && firstRender) ? formData.estimateValue : estimateValueCalculated;
      setFirstRender(false);
      currentForm = updateValueWithDiscount(estimateValueToSet, formData.applyDiscount, currentForm);
      updateEstimateValue(estimateValueToSet, currentForm);
    }
  },[
    data,
    oneOffItems,
    equipmentForEstimate,
    customersOptions.length,
    formData.days,
    updateEstimateValue,
    readyToEdit,
  ])

  const updateValueWithDiscount = useCallback((estimateValue, discount, currentForm)=>{
    let valueWithDiscount = estimateValue;
    if (discount && discount > 0 && discount < 100 && estimateValue) {
      let discountPercent = discount / 100;
      let amountDiscounted = estimateValue * discountPercent;
      valueWithDiscount = estimateValue - amountDiscounted;
    }
    currentForm.applyDiscount.valid =
      discount >= 0 && discount <= 100 ? true : false;
    const newForm = {
      ...currentForm,
      estimateValue: {
        ...currentForm.estimateValue,
        valueWithDiscount: valueWithDiscount,
      },
    }
    setForm(newForm)
    return newForm;
  },[setForm])

  useEffect(()=>{
    if(form?.estimateValue?.value && (!estimateId || readyToEdit)) {
      updateValueWithDiscount(form.estimateValue.value, form.applyDiscount?.value, form);
    }
  }, [
    form?.estimateValue?.value,
    form?.applyDiscount?.value,
    readyToEdit,
  ])


  const onChangeInput = ({ input, value }) => {
    if (input !== "files") {
      setForm({ ...form, [input]: { ...form[input], value } });
      setFormData({ ...formData, [input]: value });
    } else {
      setFormData({ ...formData, [input]: value });
    }
  };

  const handleAddItemsCollection = async () => {
    const collectionsOptions = itemsCollection.map(collection => ({
      value: collection.id,
      label: collection.name,
    }));
    viewContext.modal.show(
      {
        title: `Add collection of items`,
        formClass: "justify-between non-off-item-form",
        form: {
          collection: {
            type: 'select',
            label: 'Collection',
            options: collectionsOptions,
            required: true,
          }
        },
        buttonText: "Save",
      },
      ({collection}) => {
        const collectionItems = itemsCollection
          .find(col => col.id === collection)
          .items
          .filter(collectionItem => !oneOffItems.find(listItem => listItem.id === collectionItem.id));
        const newOneOffItemsArray = [
          ...oneOffItems,
          ...collectionItems.map(item=>({
              ...item,
              itemQuantity: 1,
              totalPrice: item.itemPrice,
            })
          )
        ];
        setOneOffItems(newOneOffItemsArray);
        viewContext.modal.hide();
      }
    )
  }

  const handleAddEquipment = async () => {
    const equipmentOptions = equipments.map(equipment => ({
      value: equipment.id,
      label: equipment.name,
    }));
    viewContext.modal.show(
      {
        title: `Add equipment`,
        formClass: "justify-between non-off-item-form",
        form: {
          equipment: {
            type: 'select',
            label: 'Equipment Name',
            options: equipmentOptions,
            required: true,
          },
          timeType: {
            type: 'radio',
            label: 'Time Needed',
            options: [
              {
                value: 'hours_needed',
                label: 'Hours Needed',
              },
              {
                value: 'days_needed',
                label: 'Days Needed',
              },
            ],
            required: true,
          },
          timeNeeded: {
            type: 'number',
            required: true,
            min: 0,
          },
        },
        buttonText: "Save",
      },
      (form) => {
        const equipment = equipments
          .find(eq => eq.id === form.equipment);
        const rate = form.timeType === 'hours_needed' ? equipment.hourly_rate : equipment.daily_rate;
        equipment.totalCost = parseFloat(form.timeNeeded) * parseFloat(rate);
        const newEquipmentArray = [
          ...equipmentForEstimate,
          {
            ...equipment,
            ...form,
          }
        ];
        setEquipmentForEstimate(newEquipmentArray);
        viewContext.modal.hide();
      }
    )
  }

  const onDeleteEquipment = equipmentIndex => {
    equipmentForEstimate.splice(equipmentIndex, 1);
    setEquipmentForEstimate([...equipmentForEstimate]);
  }
  const onEditEquipment = equipmentIndex => {
    const equipmentToEdit = equipmentForEstimate.at(equipmentIndex)
    viewContext.modal.show(
      {
        title: `Edit equipment`,
        formClass: "justify-between non-off-item-form",
        form: {
          timeType: {
            type: 'radio',
            label: 'Time Needed',
            options: [
              {
                value: 'hours_needed',
                label: 'Hours Needed',
              },
              {
                value: 'days_needed',
                label: 'Days Needed',
              },
            ],
            default: equipmentToEdit.timeType,
            required: true,
          },
          timeNeeded: {
            type: 'number',
            required: true,
            min: 0,
            value: equipmentToEdit.timeNeeded,
          },
        },
        buttonText: "Save",
      },
      (form) => {
        const rate = form.timeType === 'hours_needed' ? equipmentToEdit.hourly_rate : equipmentToEdit.daily_rate;
        equipmentToEdit.timeType = form.timeType;
        equipmentToEdit.timeNeeded = form.timeNeeded;
        equipmentToEdit.totalCost = parseFloat(form.timeNeeded) * parseFloat(rate);
        setEquipmentForEstimate([...equipmentForEstimate]);
        viewContext.modal.hide();
      }
    )
  }

  const handleAddItem = async () => {
    viewContext.modal.show(
      {
        title: `Add one-off items`,
        modalContentClass: "full-width",
        formClass: "justify-between non-off-item-form",
        form: itemBaseForm(itemTypes),
        buttonText: "Save",
      },
      (data) => {
        const newItemToAdd = {
          ...data,
          itemPrice: data.itemCostProfitPrice.item_price,
          itemCost: data.itemCostProfitPrice.item_cost,
          profit_percentage: data.itemCostProfitPrice.profit_percentage,
          totalPrice: (data.itemQuantity * data.itemCostProfitPrice.item_price).toFixed(2),
          itemType: itemTypes.find((type) => type.value === data.itemType),
          costPer: costPerOptions.find(
            (option) => option.value === data.costPer
          ),
        };
        const newOneOffItemsArray = [
          ...oneOffItems,
          newItemToAdd,
        ];
        setOneOffItems(newOneOffItemsArray);
        viewContext.modal.hide();
      }
    );
  };

  const submit = async () => {
    if (form.applyDiscount.value && !form.applyDiscount.valid) return;
    if (!form.customer.value || form.customer.value === "unselected") {
      form.customer.valid = false;
      setForm({ ...form });
      return;
    }
    form.save_pricebook = form.savePriceBook;
    form.items = oneOffItems;
    form.equipment = equipmentForEstimate;
    if (!form.applyDiscount.value) {
      form.applyDiscount.value = 0;
    }
    setSaving(true);
    try {
      if (estimateId) {
        await updateJob(form, estimateId);
      } else {
        await createJob(form);
      }
      viewContext.notification.show("Saved successfully!", "success", true);
      goToEstimates();
    } catch (e) {
      delete form.save_pricebook;
      delete form.items;
      delete form.equipment;
      viewContext.notification.show(
        "There's been an error, please check all the information is right and try again!",
        "error",
        true
      );
    } finally {
      setSaving(false);
    }
  };

  const onDeleteItem = (id) => {
    viewContext.modal.show(
      {
        title: `Delete Item from Estimate`,
        form: {},
        buttonText: "Delete",
        destructive: true,
      },
      () => {
        oneOffItems.splice(id, 1);
        setOneOffItems([...oneOffItems]);
        viewContext.modal.hide();
      }
    );
  };

  const addInventoryItem = (_, item) => {
    const itemRepeated = oneOffItems.find(
      (oneoffitem) => oneoffitem.inventoryItemId === item.id
    );
    const modalTitle = itemRepeated
      ? "This Item has already been added, do you want to modify its Quantity?"
      : `Enter Quantity`;
    viewContext.modal.show(
      {
        title: modalTitle,
        form: {
          quantity: {
            type: "number",
            label: "Quantity",
            required: true,
            min: 1,
          },
        },
        buttonText: "Add",
      },
      ({ quantity }) => {
        if (itemRepeated) {
          itemRepeated.itemQuantity = quantity;
        } else {
          oneOffItems.push({
            ...item,
            inventoryItemId: item.id,
            totalPrice: (quantity * item.itemPrice).toFixed(2),
            itemQuantity: quantity,
          });
        }
        setOneOffItems([...oneOffItems]);
        viewContext.modal.hide();
      }
    );
  };

  const onEditItem = (index) => {
    const item = oneOffItems[index];
    const isInventoryItem = !!(estimateId ? item.inventoryItemId : item.id);
    viewContext.modal.show(
      {
        title: "Edit Quantity and Profit",
        formClass: "flex-force flex-wrap justify-between",
        form: isInventoryItem
          ? {
              profit_percentage: {
                customComponent: ShowCostPriceAndEditPercentage,
                label: 'Profit Percentage',
                sufix: '%',
                value: item.profit_percentage,
                cost: item.itemCost,
                decimals: true,
                getPriceByCostAndProfitPercentage: getPriceByCostAndProfitPercentage,
              },
              itemQuantity: {
                type: "number",
                label: "Quantity",
                required: true,
                value: item.itemQuantity,
                min: 1,
                containerClass: "w-full",
              },
            }
          : itemBaseForm(itemTypes, item),
        buttonText: "Save",
      },
      (form) => {
        const { itemQuantity, profit_percentage } = form;
        if (isInventoryItem) {
          item.itemQuantity = itemQuantity;
          item.itemPrice = getPriceByCostAndProfitPercentage(item.itemCost, profit_percentage);
          item.profit_percentage = profit_percentage;
          item.totalPrice = (itemQuantity * item.itemPrice).toFixed(2);
        } else {
          oneOffItems[index] = {
            ...form,
            itemPrice: form.itemCostProfitPrice.item_price,
            itemCost: form.itemCostProfitPrice.item_cost,
            profit_percentage: form.itemCostProfitPrice.profit_percentage,
            totalPrice: (itemQuantity * form.itemCostProfitPrice.item_price).toFixed(2),
            itemType: itemTypes.find((type) => type.value === form.itemType),
            costPer: costPerOptions.find(
              (option) => option.value === form.costPer
            ),
          };
        }
        setOneOffItems([...oneOffItems]);
        viewContext.modal.hide();
      }
    );
  };

  return {
    onChangeInput,
    handleAddItem,
    handleAddItemsCollection,
    handleAddEquipment,
    submit,
    form,
    oneOffItems,
    equipmentForEstimate,
    itemsOptions,
    onDeleteItem,
    addInventoryItem,
    onEditItem,
    onDeleteEquipment,
    onEditEquipment,
    loadingCustomers,
    saving,
    loadingEditScreen,
  };
};

export default useCreateJob;
