import { useState } from 'react';
import { Label, Error, Icon, ClassHelper } from 'components/lib';
import Style from './input.tailwind.js';
import { checkValueDifferentFromNullAndUndefinedAndNaN } from 'lib/index.js';

export function NumberInput(props){

  const [error, setError] = useState(props.errorMessage || 'Please enter a number');

  function validate(e){

    const min = checkValueDifferentFromNullAndUndefinedAndNaN(props.min) ? parseInt(props.min) : undefined;
    const max = checkValueDifferentFromNullAndUndefinedAndNaN(props.max) ? parseInt(props.max) : undefined;
    let value = e && e.target.value ? (props.decimals ? parseFloat(e.target.value) : parseInt(e.target.value)) : '';
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    else if (!props.required && !value) {
      valid = true;
      value = 0;
    }

    // now test for a valid number
    else if (isNaN(value)){
      valid = false;
      setError('Please enter a valid number');

    }

    // // check for min max
    else if (min!==undefined && max!==undefined){
      if (value >= min && value <= max){
        valid = true;
      }
      else {
        valid = false;
        setError('Please enter a number between ' + min + ' and ' + max);
      }
    }
    else if (min!==undefined){
      if (value >= min){
        valid = true;
      }
      else {
        valid = false;
        setError('Please enter a number equal to or above ' + min);
      }
    }
    else if (max!==undefined){
      if (value <= max){
        valid = true;
      }
      else {
        valid = false;
        setError('Please enter a number equal to or below ' + max);
      }
    }
    else {

      valid = true;

    }

    // update the parent form
    props.onChange?.(props.name, value, valid);

  }

  // style
  const numberStyle = ClassHelper(Style, {

    textbox: true, 
    className: props.className, 
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,

  });

  return(
    <div className={ Style.input + " " + props.containerClass }>

      { props.label && 
        <Label
          text={ props.label }
          required={ props.required }
          for={ props.name }
        /> }
      <div style={{display: 'flex'}}>
        { props.prefix &&
          <div style={{
            height: '100%',
            padding: '0.75em',
            backgroundColor: '#f2f2f2',
            border: '1px solid lightgray',
            borderRadius: '5px 0 0 5px'
          }}>
            {props.prefix}
          </div>
        }
        <input
          type='number'
          id={ props.name }
          name={ props.name }
          step={props.decimals ? '0.01' : '1'}
          className={ numberStyle }
          value={ props.value || '' }
          min={ props.min }
          max={ props.max }
          readOnly={ props.readonly }
          placeholder={ props.placeholder }
          onChange={ e => props.onChange?.(props.name, e.target.value, undefined) }
          onBlur={ e => validate(e) }
          style={props.prefix ?
            {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeft: 'none'
            } : props.sufix ?
            {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: 'none'
            } : {}}
        />
        { props.sufix &&
          <div style={{
            height: '100%',
            padding: '0.75em',
            backgroundColor: '#f2f2f2',
            border: '1px solid lightgray',
            borderRadius: '0 5px 5px 0'
          }}>
            {props.sufix}
          </div>
        }
      </div>

      { props.valid === false &&
        <Error message={ error }/> }

      { props.valid === true &&
        <Icon
          image='check'
          color='#8CC57D'
          className={ Style.successIcon }
          size={ 16 }
        />}

    </div>
  );
}
