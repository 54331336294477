import { Card, FlexContainer, Icon } from 'components/lib'
import React from 'react'

const IconTitleDescriptionCard = ({icon, title, description}) => {
  return (
    <Card outline>
      <FlexContainer>
        { icon &&
          <FlexContainer width='15%' alignItems='center'>
            <Icon image={icon} size={30}/>
          </FlexContainer>
        }
        <FlexContainer width={icon ? '85%' : '100%'} flexDirection='column' alignItems='center'>
          <h1 className='text-3xl font-semibold'>{title}</h1>
          <p>{description}</p>
        </FlexContainer>
      </FlexContainer>
    </Card>
  )
}

export default IconTitleDescriptionCard