import React from 'react'
import { Table } from "components/lib";

const ItemsTable = ({
  handleAddQuantity,
  handleDeleteItem,
  handleEditItem,
  itemsTable,
  loadingItems,
}) => {
  return (
    <Table
      search
      data={itemsTable}
      actions={(handleAddQuantity && handleDeleteItem && handleEditItem ) ? {
        custom: [{ icon: "plus-circle", action: handleAddQuantity }],
        delete: handleDeleteItem,
        edit: handleEditItem,
      } : null}
      show={[
        'itemName',
        'itemType',
        'itemQuantity',
        'costPer',
        'itemPrice',
        'itemCost',
        'itemDescription',
      ]}
      loading={loadingItems}
    />
  )
}

export default ItemsTable