import React from 'react'
import ItemBody from './itemBody'
import ItemHeader from './itemHeader'


const Item = ({item, index, onDelete, onEdit}) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '280px',
      border: '1px solid lightgrey',
      borderRadius: '10px',
      padding:'20px',
      gap:'10px',
      height:'fit-content',
    }}>
      <ItemHeader itemName={item.itemName} index={index} onEdit={onEdit} onDelete={onDelete}/>
      <hr/>
      <ItemBody values={item}/>
    </div>
  )
}

export default Item