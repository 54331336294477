import { Card, TabView, Table } from 'components/lib'
import React from 'react'

const HistoryLog = ({historyLogTable, switchTab}) => {
  return (
    <Card title='History'>
      <TabView
        name="jobs"
        onSwitch={switchTab}
        labels={["All", "Notes", "Files", "Others"]}
      />
      <Table
        data={historyLogTable}
        show={['message', 'dateCreated']}
      />
    </Card>
  )
}

export default HistoryLog