import ProfilePhoto from 'components/form/profilephoto/profilephoto'
import { AccountNav, Animate, Card, Form, useAPI } from 'components/lib'
import React from 'react'

const Company = ({onboarding}) => {
  // fetch
  const user = useAPI('/api/user');
  const calendars = useAPI('/api/calendars');

  return (
    <>
      {onboarding ? <></> : <AccountNav />}
      <Animate>
        <Card
          className={'w-[47%] mw-120' + (onboarding ? ' !pl-0' : '')}
        >
        { user?.data && calendars?.data &&
          <>
            <Form
              buttonText='Save'
              url='/api/user'
              method='PATCH'
              className='flex flex-wrap justify-between'
              data={{
                company_logo: {
                  customComponent: ProfilePhoto,
                  value: user.data.company_logo,
                  companyName: user.data.company_name,
                },
                calendar_id: {
                  label: 'Select the calendar',
                  type: 'select',
                  required: calendars.data ? true : false,
                  default: user.data.calendar_id,
                  options: calendars?.data?.map(x => { return {
                      value: x.id, label: x.name
                  }}),
                  errorMessage: 'Please select your calendar',
                  containerClass: 'w-full',
                },
                company_name: {
                  label: 'Company Name',
                  type: 'text',
                  required: true,
                  value: user.data.company_name,
                  errorMessage: 'Please enter your name',
                  containerClass: 'w-full',
                },
                company_address: {
                  label: 'Company address',
                  type: 'text',
                  required: true,
                  value: user.data.company_address,
                  errorMessage: 'Please enter your email address',
                  containerClass: 'w-full',
                },
                city: {
                  label: 'City',
                  type: 'text',
                  required: true,
                  value: user.data.city,
                  errorMessage: 'Please enter your email address',
                  containerClass: 'w-[30%]',
                },
                state: {
                  label: 'State',
                  type: 'text',
                  required: true,
                  value: user.data.state,
                  errorMessage: 'Please enter your email address',
                  containerClass: 'w-[30%]',
                },
                zip: {
                  label: 'ZIP',
                  type: 'text',
                  required: true,
                  value: user.data.zip,
                  errorMessage: 'Please enter your email address',
                  containerClass: 'w-[30%]',
                },
                email: {
                  label: 'Email',
                  type: 'email',
                  required: true,
                  value: user.data.email,
                  errorMessage: 'Please enter your email address',
                  containerClass: 'w-[47%]',
                },
                phone: {
                  label: 'Phone',
                  type: 'phone',
                  required: true,
                  value: user.data.phone,
                  errorMessage: 'Please enter your email address',
                  containerClass: 'w-[47%]',
                },
                faq: {
                  max: 1,
                  label: 'FAQ',
                  type: 'file',
                  // required: true,
                  value: user.data.faq,
                  errorMessage: 'Please enter your email address',
                  containerClass: 'w-[47%]',
                },
                welcome_packet: {
                  max: 1,
                  label: 'Welcome Packet',
                  type: 'file',
                  // required: true,
                  value: user.data.welcome_packet,
                  errorMessage: 'Please enter your email address',
                  containerClass: 'w-[47%]',
                },
                viewFAQ: {
                  type: "filesToKeep",
                  value: [user.data.faq],
                  containerClass: 'w-[47%] mb-4',
                },
                viewWelcomePacket: {
                  type: "filesToKeep",
                  value: [user.data.welcome_packet],
                  containerClass: 'w-[47%] mb-4',
                },
              }}
              callback={ res => {
                user.reload();
              }}
            />
          </>
        }
        </Card>
      </Animate>
    </>
  )
}

export default Company