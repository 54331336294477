import FlexContainer from 'components/flexcontainer/flexContainer';
import { Badge } from 'components/lib';
import React from 'react'

const FilesToKeep = (props) => {

  const deleteFile = (file)=>{
    const newValues = props.value.filter(fileToKeep => fileToKeep.url !== file.url);
    props.onChange(props.name, newValues, true);
  }

  const openFile = file => {
    window.open('https://' + file.url, '_blank');
  }

  return (
    <div className={props.containerClass} style={{rowGap:'10px'}}>
      {
        props.value?.map(file=> file ? <Badge key={file.url} onClick={()=>openFile(file)} delete={props.delete ? ()=>deleteFile(file) : null} text={file.filename} color='blue'/> : null)
      }
    </div>
  )
}

export default FilesToKeep