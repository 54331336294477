import { dateYYYYMMDDToMMDDYYYYTSlash } from "hooks/lib";

export const convertItemFromAPI = ({
  item_name,
  item_type_id,
  quantity_in_stock,
  cost_per,
  item_price,
  item_cost,
  description,
}) => {
  const data = {
    itemName: item_name,
    itemType: item_type_id,
    itemQuantity: quantity_in_stock,
    costPer: cost_per,
    itemPrice: item_price,
    itemCost: item_cost,
    itemDescription: description,
  };
  return data;
};

export const convertItemToAPI = ({
  itemName,
  itemType,
  costPer,
  itemQuantity,
  itemDescription,
  itemCostProfitPrice,
}) => {
  const data = {
    item_name: itemName,
    item_type_id: itemType,
    quantity_in_stock: itemQuantity,
    cost_per: costPer,
    description: itemDescription,
    item_cost: itemCostProfitPrice.item_cost,
    profit_percentage: itemCostProfitPrice.profit_percentage,
    item_price: itemCostProfitPrice.item_price,
  };
  return data;
};

export const convertJobToAPI = ({
  customer,
  days,
  applyDiscount,
  discount_expiration_date,
  estimateValue,
  extraNotes,
  files,
  files_to_keep,
  items,
  equipment,
}) => {
  const cleanItems = items?.map((item) => ({
    item_type_id: item.itemType.value,
    inventory_item_id: item.inventoryItemId || item.id,
    item_name: item.itemName,
    quantity_used: item.itemQuantity,
    item_price: item.itemPrice,
    item_cost: item.itemCost,
    cost_per: item.costPer.value,
    save_pricebook: item.savePriceBook,
    profit_percentage: item.profit_percentage,
  }));
  const cleanEquipment = equipment?.map(({id, timeNeeded, timeType}) => {
    const equipment = {
      id,
    }
    equipment[timeType] = timeNeeded;
    return equipment;
  })
  const cleanData = {
    customer_id: customer.value,
    estimate_value: estimateValue.value || 0,
    discount: applyDiscount.value || 0,
    discount_expiration_date: discount_expiration_date.value,
    days_to_complete: days.value || 0,
    extra_notes: extraNotes.value,
    items: cleanItems || [],
    files: files.value,
    equipment: cleanEquipment,
    files_to_keep: files_to_keep.value?.map((file) => file.ETag),
  };

  let formData = new FormData();

  for (let key in cleanData) {
    const value = cleanData[key];
    // append files
    if (Array.isArray(value) && value[0] && value[0].hasOwnProperty("data")) {
      for (let i = 0; i < value.length; i++) {
        formData.append(key, value[i].data);
      }
    } else if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        if (typeof value[i] === "string") {
          formData.append(`${key}[${i}]`, value[i]);
        } else {
          for (let field in value[i]) {
            formData.append(key + `[${i}][${field}]`, value[i][field]);
          }
        }
      }
    } else if (typeof value === "string" || typeof value === "number") {
      // append text values
      formData.append(key, value);
    }
  }
  return formData;
};
