import React, { useContext, useEffect, useState } from 'react'
import { getProfitability } from './../lib/services/index';
import { MainContext } from 'contexts/MainContext';

const header = [
  { name: 'job', title: 'Job', sort: true },
  { name: 'costs', title: 'Costs', sort: true },
  { name: 'margin', title: 'Margin', sort: true },
  { name: 'profit', title: 'Profit', sort: true },
]

const useProfitability = () => {
  const {
    profitabilityData,
    setProfitabilityData,
  } = useContext(MainContext);
  const [loadingProfitability, setLoadingProfitability] = useState(true);
  const [profitabilityTable, setProfitabilityTable] = useState(null);

  const reload = async () => {
    try {
      const data = await getProfitability();
      setProfitabilityData(data);
      const body = data.jobs.map(job=>({
        job: `${job.customer?.name} - ${job.job_code}`,
        costs: job.costs,
        margin: job.margin,
        profit: job.profit,
      }));
      setProfitabilityTable({header, body});
      setLoadingProfitability(false);
    } catch (e) {
      const table = {
        header,
        body: [
          {
            job: 'Lorem Ipsum',
            costs: 'Lorem Ipsum',
            margin: 'Lorem Ipsum',
            profit: 'Lorem Ipsum',
          },
          {
            job: 'Lorem Ipsum',
            costs: 'Lorem Ipsum',
            margin: 'Lorem Ipsum',
            profit: 'Lorem Ipsum',
          },
          {
            job: 'Lorem Ipsum',
            costs: 'Lorem Ipsum',
            margin: 'Lorem Ipsum',
            profit: 'Lorem Ipsum',
          },
          {
            job: 'Lorem Ipsum',
            costs: 'Lorem Ipsum',
            margin: 'Lorem Ipsum',
            profit: 'Lorem Ipsum',
          },
          {
            job: 'Lorem Ipsum',
            costs: 'Lorem Ipsum',
            margin: 'Lorem Ipsum',
            profit: 'Lorem Ipsum',
          },
          {
            job: 'Lorem Ipsum',
            costs: 'Lorem Ipsum',
            margin: 'Lorem Ipsum',
            profit: 'Lorem Ipsum',
          },
          {
            job: 'Lorem Ipsum',
            costs: 'Lorem Ipsum',
            margin: 'Lorem Ipsum',
            profit: 'Lorem Ipsum',
          },
        ]
      }
      setProfitabilityTable(table)
      setLoadingProfitability(false);
    }

  }

  useEffect(()=>{
    reload();
  },[]);

  return {
    loadingProfitability,
    profitabilityTable,
    profitabilityData,
  }
}

export default useProfitability