import React, { useContext } from 'react';
import { Button, ViewContext } from 'components/lib';
import ReactPlayer from 'react-player';

export const FilesPreview = ({files, onFileClick}) => {
  return(
    <div
      style={{
        display: 'flex',
        gap: '20px',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {
        files.map((file, index)=>(
          <div
            key={index}
            style={{
              border: '1px solid lightgray',
              borderRadius: '5px',
              padding: '10px',
              height:'200px',
              width: '300px'
            }}
            onClick={()=>onFileClick(file)}
          >
            <div
              style={{
                width: '100%',
                height: '80%',
              }}
            >
              {
                file.type === 'image' ?
                  <img
                    style={{
                      width:'100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    src={file.file}
                  /> :
                  <></>
              }
            </div>
            <div style={{display:'flex', paddingTop: '10px', justifyContent: 'center'}}>
              {file.filename}
            </div>
          </div>
        ))
      }
    </div>
  )
}

export const FileViewer = ({file, onGoBack}) => {
  return (
    <div className='fit-content pb-4'>
      <Button icon='arrow-left' className='mb-2 icon-button' action={onGoBack}/>
      { 
        file.type === 'image' ? 
            <img
              style={{
                width:'100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={file.file}
            />
          :
          <ReactPlayer
            url={file.file}
            controls={true}
          />
      }
    </div>
  )
}

export const FilesViewer = ({files}) => {
  const viewContext = useContext(ViewContext);
  const onGoBack = ()=>{
    viewContext.modal.show({
      modalContentClass: 'full-width',
      customContent: <FilesViewer files={files}/>
    })
  }
  const onFileClick = file => {
    viewContext.modal.show({
      modalContentClass: 'fit-content',
      customContent: <FileViewer onGoBack={onGoBack} file={file}/>
    })
  }
  return (
    <FilesPreview onFileClick={onFileClick} files={files}/>
  )
}