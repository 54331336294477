import React from 'react';
import FlexContainer from "components/flexcontainer/flexContainer";
import { Button, Card, Loader } from "components/lib";
import ItemsTable from 'components/itemsView/itemsTable';


const ItemsCollectionsView = ({
  handleCreateCollection,
  loadingItems,
  collectionsTables,
  handleEditCollection,
  handleDeleteCollection,
}) => {
  return (
    <Card loading={loadingItems}>
      <FlexContainer justifyContent={"flex-end"} gap={10} marginY={10}>
        <Button
          text={"New Collection"}
          color="blue"
          className="px-5"
          action={handleCreateCollection}
        />
      </FlexContainer>
      {collectionsTables ? collectionsTables.map((collection, index)=>(
        <Card
          key={index}
          outline
          title={collection.name}
          actions={[
            {
              icon: 'edit',
              action: (a)=>{handleEditCollection(collection)}
            },
            {
              icon: 'trash',
              action: (a)=>{handleDeleteCollection(collection)}
            },
          ]}
        >
          <ItemsTable
            itemsTable={collection.items}
            loadingItems={loadingItems}
          />
        </Card>
      )) : <Loader/>}
    </Card>
  )
}

export default ItemsCollectionsView