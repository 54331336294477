import IconTitleDescriptionCard from 'components/card/IconTitleDescriptionCard'
import { Card, Grid, Loader, Table } from 'components/lib'
import useProfitability from 'hooks/useProfitability'
import React from 'react'

const Profitability = () => {

  const {
    loadingProfitability,
    profitabilityTable,
    profitabilityData: {
      total_costs,
      total_margin,
      total_profit,
    },
  } = useProfitability();

  return (
    <Card>
    {
      loadingProfitability ? <Loader/> : <>
        <Grid cols={3}>
          <IconTitleDescriptionCard title={total_costs} description='Total Costs'/>
          <IconTitleDescriptionCard title={total_margin} description='Total Margin'/>
          <IconTitleDescriptionCard title={total_profit} description='Total Profit'/>
        </Grid>
        <Table
          data={profitabilityTable}
          show={['job', 'costs', 'margin', 'profit']}
          loading={loadingProfitability}
        />
      </>
    }
    </Card>
  )
}

export default Profitability