import { ViewContext } from "components/lib";
import { MainContext } from "contexts/MainContext";
import { deleteTeam, putTeam, getAllTeams, postTeam } from "lib/services";
import { useCallback, useContext, useEffect, useState } from "react";
import useEmployees from "./useEmployees";
import { createTeamForm } from "./lib";

const header = [
  { name: "accountId", title: "accountId", sort: true },
  { name: "id", title: "id", sort: true },
  { name: "name", title: "Name", sort: true },
  { name: "color", title: "Color", sort: true },
  { name: "team_members", title: "Team Members", sort: true },
];

const useTeams = () => {
  const viewContext = useContext(ViewContext);
  const { teamsData, setTeamsData, loadingTeams, setLoadingTeams } =
    useContext(MainContext);

  const [teamsTable, setTeamsTable] = useState();

  const { getEmployeesByPartialName, getEmployeesByIds } = useEmployees();

  const getTeamById = useCallback(
    (teamId) => {
      return teamsData?.find((team) => team.id === teamId);
    },
    [teamsData]
  );

  const loadTeamsTable = (teamsData) => {
    const body = teamsData.map((team) => ({
      ...team,
      color: team.color || null,
    }));
    setTeamsTable({ body, header });
  };

  const loadTeams = useCallback(async () => {
    setLoadingTeams(true);
    const teams = await getAllTeams();
    setTeamsData(teams.data);
    loadTeamsTable(teams.data);
    setLoadingTeams(false);
  }, [setTeamsData]);

  const createTeam = (team) => {
    viewContext.modal.show(
      {
        title: `Create a new team`,
        form: createTeamForm(
          getEmployeesByPartialName,
          getEmployeesByIds,
          team
        ),
        buttonText: "Save",
      },
      async (form) => {
        const team_members = form.team_members.map((member) => member.id);
        const data = {
          ...form,
          team_members,
        };
        setLoadingTeams(true);
        viewContext.modal.hide();
        try {
          await postTeam(data);
          viewContext.notification.show("Created!", "success", true);
          loadTeams();
          viewContext.modal.hide();
        } catch (err) {
          const msg = err.response?.data?.message ?? err.toString();
          viewContext.notification.show(msg || "An error occurred", "error", true);
          createTeam({
            ...form,
            team_members: form.team_members.map(tm => tm.id),
          });
        }
        setLoadingTeams(false);
      }
    );
  };

  const handleEditTeam = (team) => {
    viewContext.modal.show(
      {
        title: `Update team information`,
        form: createTeamForm(
          getEmployeesByPartialName,
          getEmployeesByIds,
          team
        ),
        buttonText: "Save",
      },
      async (form) => {
        const team_members = form.team_members.map((member) => member.id);
        const data = {
          ...form,
          team_members,
        };
        setLoadingTeams(true);
        viewContext.modal.hide();
        try {
          await putTeam(data, team.id);
          viewContext.notification.show("Saved!", "success", true);
          loadTeams();
          viewContext.modal.hide();
        } catch (err) {
          const msg = err.response?.data?.message ?? err.toString();
          viewContext.notification.show(msg || "An error occurred", "error", true);
          handleEditTeam({
            ...form,
            id: team.id,
            team_members: form.team_members.map(tm => tm.id),
          });
        }
        setLoadingTeams(false);
      }
    );
  };

  const handleDeleteTeam = (team) => {
    const { id } = team;
    viewContext.modal.show(
      {
        title: `Do you want to delete team: ${team.name}?`,
        form: {},
        destructive: true,
        buttonText: "Delete",
      },
      async () => {
        await deleteTeam(id);
        loadTeams();
        viewContext.modal.hide();
      }
    );
  };

  useEffect(() => {
    if (!teamsData) loadTeams();
  }, [loadTeams, teamsData]);

  useEffect(() => {
    if (teamsData) loadTeamsTable(teamsData);
  }, []);

  return {
    teamsData,
    createTeam,
    getTeamById,
    teamsTable,
    loadingTeams,
    handleDeleteTeam,
    handleEditTeam,
  };
};

export default useTeams;
